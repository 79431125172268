"use client";
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "../Elements/Button/Button";
import { v4 as uuidv4 } from "uuid";
import Pagination from "../Pagination/Pagination";
import { useRouter } from "next/navigation";
import { CiSearch } from "react-icons/ci";
import { useRef } from "react";

export const NewsArticles = ({ data, id, categories }) => {
  // S E T  D E F A U L T  B L O G  S E T T I N G S
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCategory, setPageCategory] = useState("All");
  const [filtered, setFiltered] = useState(false);

  const [articles, setArticles] = useState([]);
  const [insiderArticles, setInsiderArticles] = useState([]);
  const [insightArticles, setInsightArticles] = useState([]);

  const [allCategories, setAllCategories] = useState([]);
  const [allTopics, setAllTopics] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [totalPosts, setTotalPosts] = useState(1);

  const [queryFilter, setQueryFilter] = useState(""); // Assume there is at least one page

  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const { query } = router;

  // Format URLs for aria labels
  const formatted = url => {
    // Remove trailing slash if it exists
    const cleanedUrl = url.replace(/\/$/, ''); // Remove trailing slash

    // Split the URL and take the last part
    const part = cleanedUrl.split('/').pop();

    // Replace hyphens with spaces and capitalize each word
    return part
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join back into a string
  };

  // Ran when pagination is changed (triggers api get request for articles with new page number)
  const handlePageChange = (number) => {
    setPageNumber(number);

    const pageNum = document.getElementById("pageNum");
    pageNum.textContent = number;

    router.query.p = number;
    router.push(router);
  };

  useEffect(() => {
    const pageNum = document.getElementById("pageNum");

    const pageNumVal = router.query.p || 1;

    //IF THERE IS NO FILTER THEN FETCH ALL POSTS
    if (
      router.query.search === undefined &&
      router.query.topic === undefined &&
      router.query.category === undefined &&
      router.query.archive === undefined
    ) {
      const fetchAllPosts = async () => {
        try {
          setIsLoading(true); // Set loading state to true

          // Make a HEAD request to get the response headers
          const headResponse = await axios.head(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts/`
          );

          const posts = await axios.get(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10?_embed&categories_exclude=229,267&per_page=10&page=${router.query.p ? router.query.p : 1
            }`
          );

          const insiderPosts = await axios.get(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&categories=229&per_page=4`
          );
          setInsiderArticles(insiderPosts.data);

          const insightPosts = await axios.get(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&categories=267&per_page=4`
          );
          setInsightArticles(insightPosts.data);

          // Access the response headers to get the quantity of posts and pages
          const totalPosts = headResponse.headers["x-wp-total"];
          const totalPages = headResponse.headers["x-wp-totalpages"];

          setArticles(posts.data);
          setTotalPosts(totalPosts);
          setTotalPages(totalPages);

          setIsLoading(false); // Set loading state to false after data is fetched

          // Access the posts data from the response

          //console.log("Total posts:", totalPosts);
          //console.log("Total pages:", totalPages);

          return {
            articles,
            totalPosts,
            totalPages,
          };
        } catch (error) {
          console.error("Error fetching all posts:", error);
          return [];
        }
      };

      fetchAllPosts();

      //IF FILTER DOES EXIST
    } else {
      setFiltered(true);

      const categoryVal = document.getElementById("category");
      const topicVal = document.getElementById("topic");
      const searchVal = document.getElementById("searchValue");
      const archiveVal = document.getElementById("archiveValue");
      const pageNum = router.query.p || 1;

      switch (true) {
        //C A T E G O R I E S
        case router.query.category !== undefined:
          categoryVal.textContent = router.query.category;
          topicVal.textContent = "";
          archiveVal.textContent = "";
          searchVal.textContent = "";

          const fetchPosts = async () => {
            try {
              setIsLoading(true); // Set loading state to true

              // Make a HEAD request to get the response headers
              const headResponse = await axios.head(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&categories=" +
                router.query.category
              );

              //GET DATA FILTERED BY CATEGORY
              const posts = await axios.get(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&categories=" +
                router.query.category
              );

              //SET POST NUMBER AND PAGE NUMBER
              const totalPosts = headResponse.headers["x-wp-total"];
              const totalPages = headResponse.headers["x-wp-totalpages"];

              //SET USE STATES
              setArticles(posts.data);
              setTotalPosts(totalPosts);
              setTotalPages(totalPages);

              setIsLoading(false); // Set loading state to true
            } catch (e) {
              console.log(e);
            }
          };

          fetchPosts();
          break;

        // T O P I C S
        case router.query.topic !== undefined:
          categoryVal.textContent = "";
          topicVal.textContent = router.query.topic;
          archiveVal.textContent = "";
          searchVal.textContent = "";

          const fetchPostsTags = async () => {
            try {
              setIsLoading(true); // Set loading state to true

              // Make a HEAD request to get the response headers
              const headResponse = await axios.head(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&tags=" +
                router.query.topic
              );

              //GET DATA FILTERED BY CATEGORY
              const posts = await axios.get(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&tags=" +
                router.query.topic
              );

              //SET POST NUMBER AND PAGE NUMBER
              const totalPosts = headResponse.headers["x-wp-total"];
              const totalPages = headResponse.headers["x-wp-totalpages"];

              //SET USE STATES
              setArticles(posts.data);
              setTotalPosts(totalPosts);
              setTotalPages(totalPages);

              setIsLoading(false); // Set loading state to true
            } catch (e) {
              console.log(e);
            }
          };

          fetchPostsTags();
          break;

        // Y E A R S
        case router.query.archive !== undefined:
          categoryVal.textContent = "";
          topicVal.textContent = "";
          archiveVal.textContent = router.query.archive;
          searchVal.textContent = "";

          const fetchPostsYears = async () => {
            const yearVal = router.query.archive;
            const endOfYear = `${yearVal}-12-31T00:00:00Z`;
            const startOfYear = `${yearVal}-01-01T00:00:00Z`;

            try {
              setIsLoading(true); // Set loading state to true

              // Make a HEAD request to get the response headers
              const headResponse = await axios.head(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&modified_before=" +
                endOfYear +
                "&modified_after=" +
                startOfYear
              );

              //GET DATA FILTERED BY CATEGORY
              const posts = await axios.get(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&modified_before=" +
                endOfYear +
                "&modified_after=" +
                startOfYear
              );

              //SET POST NUMBER AND PAGE NUMBER
              const totalPosts = headResponse.headers["x-wp-total"];
              const totalPages = headResponse.headers["x-wp-totalpages"];

              //SET USE STATES
              setArticles(posts.data);
              setTotalPosts(totalPosts);
              setTotalPages(totalPages);

              setIsLoading(false); // Set loading state to true
            } catch (e) {
              console.log(e);
            }
          };

          fetchPostsYears();

          break;

        case router.query.search !== undefined:
          categoryVal.textContent = "";
          topicVal.textContent = "";
          archiveVal.textContent = "";
          searchVal.textContent = router.query.search;

          const fetchPostsSearch = async () => {
            const searchVal = router.query.search;

            try {
              setIsLoading(true); // Set loading state to true

              // Make a HEAD request to get the response headers
              const headResponse = await axios.head(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&search=" +
                searchVal
              );

              //GET DATA FILTERED BY CATEGORY
              const posts = await axios.get(
                `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
                pageNum +
                "&search=" +
                searchVal
              );

              //SET POST NUMBER AND PAGE NUMBER
              const totalPosts = headResponse.headers["x-wp-total"];
              const totalPages = headResponse.headers["x-wp-totalpages"];

              //SET USE STATES
              setArticles(posts.data);
              setTotalPosts(totalPosts);
              setTotalPages(totalPages);

              setIsLoading(false); // Set loading state to true
            } catch (e) {
              console.log(e);
            }
          };

          fetchPostsSearch();

          break;
      }
    }
  }, [router.query, pageNumber]);

  //FETCH POSTS WHEN PAGE NUMBER CHANGES
  useEffect(() => {
    //CHECK IF FILTERED
    const categoryVal = document.getElementById("category");
    const topicVal = document.getElementById("topic");
    const searchVal = document.getElementById("searchValue");
    const archiveVal = document.getElementById("archive");

    const pageNum = router.query.p || 1;

    if (
      categoryVal.textContent ||
      topicVal.textContent ||
      archiveVal.textContent ||
      searchVal.textContent
    ) {
    } else {
      const fetchPosts = async () => {
        try {
          setIsLoading(true); // Set loading state to true //

          const headResponse = await axios.head(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&page=` +
            pageNum
          );

          // Make a HEAD request to get the response headers
          const posts = await axios.get(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&per_page=10&categories_exclude=229,267&page=` +
            pageNum
          );
          const insiderPosts = await axios.get(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/posts?_embed&categories=229&per_page=4`
          );
          setInsiderArticles(insiderPosts.data);
          // Access the response headers to get the quantity of posts and pages
          const totalPosts = headResponse.headers["x-wp-total"];
          const totalPages = headResponse.headers["x-wp-totalpages"];

          setArticles(posts.data);
          setTotalPosts(totalPosts);
          setTotalPages(totalPages);

          setIsLoading(false); // Set loading state to true
        } catch (e) {
          console.log(e);
        }
      };

      fetchPosts();
    }
  }, [pageNumber]);

  //G E T  F E A T U R E D  I M A G E

  const getFeaturedImage = (obj) => {
    const featuredDetails = obj._embedded["wp:featuredmedia"]
      ? obj._embedded["wp:featuredmedia"][0].media_details
      : "";
    const mediumLarge = featuredDetails ? featuredDetails.sizes.medium_large : "";
    let featured = "";
    if (mediumLarge) {
      featured = mediumLarge.source_url;
    } else if (featuredDetails) {
    }

    if (!featured && obj.featured_image_src) {
      featured = obj.featured_image_src;
    }

    if (!featured) {
      featured = obj._embedded["wp:featuredmedia"][0].source_url;
    }

    if (!featured) {
      featured = obj.x_featured_media;
    }
    return featured;
  };

  const getFeaturedImageAlt = (obj) => {
    const featuredDetails = obj._embedded["wp:featuredmedia"]
      ? obj._embedded["wp:featuredmedia"][0]
      : null;
    let altTag = "";

    if (featuredDetails && featuredDetails.alt_text) {
      altTag = featuredDetails.alt_text;
    } else if (obj.featured_image_alt) {
      altTag = obj.featured_image_alt;
    } else {
      altTag = "not found";
    }

    return altTag;
  };

  useEffect(() => {
    const fetchAllCats = async () => {
      try {
        const cats = await axios.get(
          `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/categories?per_page=100`
        );

        setAllCategories(cats.data);

        return {
          allCategories,
        };
      } catch (error) {
        console.error("Error fetching all posts:", error);
        return [];
      }
    };

    fetchAllCats();

    const fetchAllTopics = async () => {
      try {
        const tags = await axios.get(
          `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/tags?per_page=100`
        );

        setAllTopics(tags.data);

        return {
          allTopics,
        };
      } catch (error) {
        console.error("Error fetching all posts:", error);
        return [];
      }
    };

    fetchAllTopics();
  }, []);

  function generateYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 2016;

    const yearsArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearsArray.push(year);
    }

    return yearsArray;
  }

  const allArchives = generateYearsArray();

  const CategoryNameComponent = ({ categoryId }) => {
    const [categoryName, setCategoryName] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchCategoryName = async () => {
        try {
          const response = await fetch(
            `api/proxy?url=${process.env.NEXT_PUBLIC_BE}/wp-json/wp/v2/categories/${categoryId}`
          );
          const categoryData = await response.json();

          if (categoryData && categoryData.name) {
            setCategoryName(categoryData.name);
            setLoading(false);
          } else {
            setError("Category not found");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data from WordPress:", error);
          setError("Error fetching data");
          setLoading(false);
        }
      };

      fetchCategoryName();
    }, [categoryId]);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>{error}</div>;
    }

    return (
      <ReactMarkdown className=" text-[14px]" rehypePlugins={[rehypeRaw]}>
        {categoryName}
      </ReactMarkdown>
    );
  };

  const inputRef = useRef(null);

  //redirects user to news page with applied filter if selected
  const categoryChange = (event) => {
    const selectedValue = event.target.value;
    const selectedTextValue =
      event.target.options[event.target.selectedIndex].text;

    //console.log(selectedValue);
    router.push(
      "/news?category=" +
      encodeURIComponent(selectedValue) +
      "&p=1" +
      "&cat=" +
      encodeURIComponent(selectedTextValue)
    );
  };

  //redirects user to news page with applied filter if selected
  const topicChange = (event) => {
    const selectedValue = event.target.value;
    const selectedTextValue =
      event.target.options[event.target.selectedIndex].text;

    //console.log(selectedValue);
    router.push(
      "/news?topic=" +
      encodeURIComponent(selectedValue) +
      "&p=1" +
      "&top=" +
      encodeURIComponent(selectedTextValue)
    );
  };

  //redirects user to news page with applied filter if selected
  const archiveChange = (event) => {
    const selectedValue = event.target.value;

    //console.log(selectedValue);
    router.push("/news?archive=" + encodeURIComponent(selectedValue) + "&p=1");
  };

  //redirects user to news page with applied search filter if selected
  const searchButton = () => {
    const search = document.getElementById("searchBox").value;

    //console.log(search)

    router.push("/news?search=" + encodeURIComponent(search) + "&p=1");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (document.activeElement === inputRef.current) {
        searchButton();
      }
    }
  };

  function removeCodeStartingWithAV(inputString) {
    const regex = /\[av[^]*?]/g;
    return inputString.replace(regex, "");
  }

  return (
    <>
      <p className="hiddenText" id="pageNum"></p>
      <p className="hiddenText" id="category"></p>
      <p className="hiddenText" id="topic"></p>
      <p className="hiddenText" id="searchValue"></p>
      <p className="hiddenText" id="archiveValue"></p>

      <section id={id} className="NewsArticles relative lg:my-[100px]">
        <div id="section2" className="w-[85%] mx-auto ">

          <h1 className={`lg:!text-left !w-[100%] HeaderTwo text-[37px] md:text-[2.8rem] text-center font-semibold leading-[1.1] w-[85%] lg:w-[40vw]`}>{data.header}</h1>

          <h5 className=" mt-[50px] text-[20px] md:text-2xl text-center lg:text-left font-normal lg:mx-[0] mx-auto w-[85%] lg:w-[40vw] ">
            {data.subheader}
          </h5>

          {/* FILTERS */}
          <div className=" gap-[25px] w-[100%] lg:w-[100%] flex flex-col justify-between lg:flex-row my-[50px] ">
            <div className="w-[100%] h-[100%] flex flex-col md:flex-row gap-[25px] ">
              <select
                onChange={topicChange}
                className="w-[100%] lg:w-[33%] text-[22px] border-b-[0.5px] border-[#949494] py-[10px] font-semibold"
                id="topics"
              >
                <option value="all">Topics</option>
                {allTopics.map((topic) => {
                  return (
                    <option key={uuidv4()} value={topic.id}>
                      <span>
                        <ReactMarkdown
                          className=" text-[16px]"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {topic.name}
                        </ReactMarkdown>
                      </span>
                    </option>
                  );
                })}
              </select>

              <select
                onChange={categoryChange}
                className="w-[100%] lg:w-[33%]  text-[22px] border-b-[0.5px] border-[#949494] py-[10px] font-semibold"
                id="categories"
              >
                <option value="all">Categories</option>

                {allCategories.map((category) => {
                  return (
                    <option key={uuidv4()} value={category.id}>
                      <span>
                        <ReactMarkdown
                          className=" text-[16px]"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {category.name}
                        </ReactMarkdown>
                      </span>
                    </option>
                  );
                })}
              </select>

              <select
                onChange={archiveChange}
                className="w-[100%] lg:w-[33%] text-[22px] border-b-[0.5px] border-[#949494] py-[10px] font-semibold"
                id="archive"
              >
                <option value="all">Archives</option>
                {allArchives.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-[100%] relative lg:w-[25%] flex flex-row bg-[#fff] ">
              <input
                ref={inputRef}
                onKeyPress={handleKeyPress}
                id="searchBox"
                className="w-[100%] text-[22px] pl-[20px] pr-[50px] !border-[#949494] outline-none bg-[#fff]"
                type="search"
                placeholder="Search..."
                aria-label="Search posts..."
              />
              <button className="absolute right-[20px] top-1/2 translate-y-[-50%]" aria-label="Search Posts" onClick={searchButton}>
                <CiSearch size={30} color={"#000"} />
              </button>
            </div>
          </div>
        </div>

        <div className="w-[85%] flex flex-col lg:flex-row justify-start items-start mx-auto">
          <div className="w-[100%]">
            <h3 className="mt-[15px] mb-[40px] w-[100%] h-[auto] flex">
              {!router.query.search ? (
                !router.query.topic ? (
                  !router.query.category ? (
                    !router.query.archive ? (
                      "Latest News"
                    ) : (
                      <p>Search results for `{router.query.archive}`</p>
                    )
                  ) : (
                    <p>Search results for `{router.query.cat}`</p>
                  )
                ) : (
                  <p>Search results for `{router.query.top}`</p>
                )
              ) : (
                <p>Search results for `{router.query.search}`</p>
              )}
            </h3>

            <div className="w-[100%] order-1 lg:order-1 lg:w-[100%] h-[auto] flex flex-wrap justify-start items-start gap-[1%]  ">
              {articles?.length > 0 ? (
                articles?.map((article, index) => {
                  //console.log(article)
                  return (
                    <div
                      tabIndex="0"
                      key={uuidv4()}
                      className={
                        index === 0
                          ? "flex flex-col w-[100%] articleTab mb-[50px] "
                          : "articleTab overflow-hidden w-[100%] lg:w-[32%] h-auto flex  flex-col  relative mb-[50px] "
                      }
                    >
                      <div
                        className={
                          index === 0
                            ? "h-[100%] w-[100%] relative flex flex-col lg:flex-row gap-[50px]"
                            : "h-[100%] w-[100%] relative"
                        }
                      >
                        <div
                          className={
                            index === 0
                              ? "flex flex-row justify-between lg:hidden "
                              : "flex flex-row justify-between"
                          }
                        >
                          <p className="text-[14px] text-700">
                            {" "}
                            {new Date(article?.date).toLocaleDateString(
                              "en-GB"
                            )}{" "}
                          </p>
                          <span className="font-bold">
                            <CategoryNameComponent
                              categoryId={article?.categories[0]}
                            />
                          </span>
                        </div>

                        <div
                          className={
                            index === 0
                              ? " flex justify-start relative aspect-square overflow-hidden lg:overflow-visible "
                              : "aspect-square relative overflow-hidden"
                          }
                        >
                          <img
                            alt={getFeaturedImageAlt(article)}
                            className={
                              index === 0
                                ? "mx-auto w-[100%] h-[100%] lg:!max-w-[400px] lg:w-[400px] lg:h-[400px] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
                                : "h-[100%] w-[100%] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
                            }
                            src={getFeaturedImage(article)}
                            fetchpriority="high"
                          />
                        </div>

                        <div
                          className={
                            index === 0
                              ? "flex flex-col justify-center items-start px-[0px]"
                              : "lg:h-[250px] flex flex-col "
                          }
                        >
                          <ReactMarkdown
                            className={
                              index === 0
                                ? "w-[100%] lg:w-[75%] text-[20px] lg:text-[26px] font-semibold pt-[10px]"
                                : " mt-[20px] excerptP text-[20px] font-semibold pt-[10px]"
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {article?.title?.rendered}
                          </ReactMarkdown>

                          <ReactMarkdown
                            className={
                              index === 0
                                ? " w-[100%] lg:w-[75%] text-[16px] pt-[10px]"
                                : " excerptP mt-[20px] w-[100%] text-[16px] pt-[10px]"
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {removeCodeStartingWithAV(article?.excerpt?.rendered)}
                          </ReactMarkdown>

                          <Button

                          aria={formatted(article.slug)}
                            classes={
                              index === 0 ? "" : "flex md:!absolute bottom-0 "
                            }
                            url={"/news/" + article?.slug}
                          >
                            Read More
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : isLoading ? (
                <p>Loading...</p>
              ) : (
                <p className=" w-[100%] ">
                  Sorry, no posts matched your criteria. Please try another
                  search
                  <br />
                  <br />
                  You might want to consider some of our suggestions to get
                  better results:
                  <br /> <br />
                  Check your spelling.
                  <br />
                  Try a similar keyword, for example: tablet instead of laptop.
                  <br />
                  Try using more than one keyword.
                </p>
              )}
            </div>

            {/* <div className="w-[100%] h-[100%] lg:hidden flex flex-col gap-[25px] my-[50px] ">
              <select
                onChange={topicChange}
                className="bg-[#fff] w-[100%] lg:w-[33%] text-[22px] border-b-[0.5px] border-[#999] py-[10px] font-semibold"
                id="topics"
              >
                <option value="all">Topics</option>
                {allTopics.map((topic) => {
                  return (
                    <option key={uuidv4()} value={topic.id}>
                      <span>
                        <ReactMarkdown
                          className=" text-[16px]"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {topic.name}
                        </ReactMarkdown>
                      </span>
                    </option>
                  );
                })}
              </select>

              <select
                onChange={categoryChange}
                className="w-[100%] lg:w-[33%] bg-[#fff]  text-[22px] border-b-[0.5px] border-[#999] py-[10px] font-semibold"
                id="categories"
              >
                <option value="all">Categories</option>

                {allCategories.map((category) => {
                  return (
                    <option key={uuidv4()} value={category.id}>
                      <span>
                        <ReactMarkdown
                          className=" text-[16px]"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {category.name}
                        </ReactMarkdown>
                      </span>
                    </option>
                  );
                })}
              </select>

              <select
                onChange={archiveChange}
                className="w-[100%] bg-[#fff]  lg:w-[33%] text-[22px] border-b-[0.5px] border-[#999] py-[10px] font-semibold"
                id="archive"
              >
                <option value="all">Archives</option>
                {allArchives.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div> */}

            {totalPages > 1 ? (
              <Pagination
                handlePageChange={handlePageChange}
                numberOfArticles={totalPosts}
                totalPages={totalPages}
                currentPage={Number.parseInt(router.query.p || 1)}
              />
            ) : null}
          </div>
        </div>
      </section>

      {/* I N S I D E  S T O R Y */}

      {!!insiderArticles?.length && (
        <div className="bg-gradient-to-b from-[#faf8f8] to-white py-[50px]">
          <div className=" w-[85%] mx-auto ">
            <h3 className="mt-[15px] mb-[40px] w-[100%] h-[auto] flex">
              Inside Story
            </h3>
            <div className="w-[100%] order-1 lg:order-1 lg:w-[100%] h-[auto] flex flex-wrap justify-start items-start gap-[1%]  ">
              {insiderArticles?.map((article, index) => {
                return (
                  <>
                    <div
                      tabIndex="0"
                      key={uuidv4()}
                      className={
                        index === 0
                          ? "flex flex-col w-[100%] articleTab mb-[50px] "
                          : "articleTab overflow-hidden w-[100%] lg:w-[32%] h-auto flex  flex-col  relative mb-[50px] "
                      }
                    >
                      <div
                        className={
                          index === 0
                            ? "h-[100%] w-[100%] relative flex flex-col lg:flex-row gap-[50px]"
                            : "h-[100%] w-[100%] relative"
                        }
                      >
                        <div
                          className={
                            index === 0
                              ? "flex flex-row justify-between lg:hidden "
                              : "flex flex-row justify-between"
                          }
                        >
                          <p className="text-[14px] text-700">
                            {" "}
                            {new Date(article.date).toLocaleDateString(
                              "en-US"
                            )}{" "}
                          </p>
                        </div>

                        <div
                          className={
                            index === 0
                              ? " flex justify-start relative aspect-square overflow-hidden lg:overflow-visible "
                              : "aspect-square relative overflow-hidden"
                          }
                        >
                          <img
                            alt={getFeaturedImageAlt(article)}
                            className={
                              index === 0
                                ? "mx-auto w-[100%] h-[100%] lg:!max-w-[400px] lg:w-[400px] lg:h-[400px] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
                                : "h-[100%] w-[100%] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
                            }
                            src={getFeaturedImage(article)}
                            fetchpriority="high"
                          />
                        </div>

                        <div
                          className={
                            index === 0
                              ? "flex flex-col justify-center items-start px-[0px]"
                              : "lg:h-[250px] flex flex-col "
                          }
                        >
                          <ReactMarkdown
                            className={
                              index === 0
                                ? "w-[100%] lg:w-[75%] text-[20px] lg:text-[26px] font-semibold pt-[10px]"
                                : " mt-[20px] excerptP text-[20px] font-semibold pt-[10px]"
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {article?.title?.rendered}
                          </ReactMarkdown>

                          <ReactMarkdown
                            className={
                              index === 0
                                ? " w-[100%] lg:w-[75%] text-[16px] pt-[10px]"
                                : " excerptP mt-[20px] w-[100%] text-[16px] pt-[10px]"
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {removeCodeStartingWithAV(article?.excerpt?.rendered)}
                          </ReactMarkdown>

                          <Button
                            aria={formatted(article?.slug)}
                            classes={
                              index === 0 ? "" : "flex md:!absolute bottom-0 "
                            }
                            url={"/news/" + article?.slug}
                          >
                            Read More
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <Button
            aria={"View more Inside Story Posts."}
            classes="!flex justify-center  mx-auto self-center"
            url="/news?category=229&p=1&cat=Inside%20Story"
          >
            View More
          </Button>
        </div>
      )}

      {/* I N S I G H T ewee3 */}

      {!!insightArticles?.length && (
        <div className="bg-[#fff] py-[50px]">
          <div className=" w-[85%] mx-auto ">
            <h3 className="mt-[15px] mb-[40px] w-[100%] h-[auto] flex">
              Insight
            </h3>
            <div className="w-[100%] order-1 lg:order-1 lg:w-[100%] h-[auto] flex flex-wrap justify-start items-start gap-[1%]  ">
              {insightArticles?.map((article, index) => {
                return (
                  <>
                    <div
                      tabIndex="0"
                      key={uuidv4()}
                      className={
                        index === 0
                          ? "flex flex-col w-[100%] articleTab mb-[50px] "
                          : "articleTab overflow-hidden w-[100%] lg:w-[32%] h-auto flex  flex-col  relative mb-[50px] "
                      }
                    >
                      <div
                        className={
                          index === 0
                            ? "h-[100%] w-[100%] relative flex flex-col lg:flex-row gap-[50px]"
                            : "h-[100%] w-[100%] relative"
                        }
                      >
                        <div
                          className={
                            index === 0
                              ? "flex flex-row justify-between lg:hidden "
                              : "flex flex-row justify-between"
                          }
                        >
                          <p className="text-[14px] text-700">
                            {" "}
                            {new Date(article?.date).toLocaleDateString(
                              "en-US"
                            )}{" "}
                          </p>
                        </div>

                        <div
                          className={
                            index === 0
                              ? " flex justify-start relative aspect-square overflow-hidden lg:overflow-visible "
                              : "aspect-square relative overflow-hidden"
                          }
                        >
                          <img
                            alt={getFeaturedImageAlt(article)}
                            className={
                              index === 0
                                ? "mx-auto w-[100%] h-[100%] lg:!max-w-[400px] lg:w-[400px] lg:h-[400px] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
                                : "h-[100%] w-[100%] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
                            }
                            src={getFeaturedImage(article)}
                            fetchpriority="high"
                          />
                        </div>

                        <div
                          className={
                            index === 0
                              ? "flex flex-col justify-center items-start px-[0px]"
                              : "lg:h-[250px] flex flex-col "
                          }
                        >
                          <ReactMarkdown
                            className={
                              index === 0
                                ? "w-[100%] lg:w-[75%] text-[20px] lg:text-[26px] font-semibold pt-[10px]"
                                : " mt-[20px] excerptP text-[20px] font-semibold pt-[10px]"
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {article?.title?.rendered}
                          </ReactMarkdown>

                          <ReactMarkdown
                            className={
                              index === 0
                                ? " w-[100%] lg:w-[75%] text-[16px] pt-[10px]"
                                : " excerptP mt-[20px] w-[100%] text-[16px] pt-[10px]"
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {removeCodeStartingWithAV(article?.excerpt?.rendered)}
                          </ReactMarkdown>

                          <Button
                            aria={formatted(article?.slug)}
                            classes={
                              index === 0 ? "" : "flex md:!absolute bottom-0 "
                            }
                            url={"/news/" + article?.slug}
                          >
                            Read More
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <Button
            aria={"View nore Insight story posts"}
            classes="!flex justify-center  mx-auto self-center"
            url="/news?category=267&p=1&cat=Insight"
          >
            View More
          </Button>
        </div>
      )}
    </>
  );
};
