"use client"
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Button } from "../Elements/Button/Button";
import { useEffect } from "react";
import { useState } from "react";

export const CenterImage = ({ data, id }) => {
  const [screenWidth, setScreenWidth] = useState();

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  //console.log(screenWidth)

  const imagewidth = screenWidth < 700 ? "95%" : data.image_width;

  return (
    <>
      <section id={id} className="CenterImage relative mt-[75px] ">
        <div
          style={{ width: `${imagewidth}` }}
          className="w-[90%] lg:w- mx-auto flex justify-center "
        >
          <img
            src={data.image.sizes.large}
            alt={data.image.alt ? data.image.alt : ""}
          />
        </div>
      </section>
    </>
  );
};
