"use client"
import Image from "next/image";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import { TfiAngleUp } from "react-icons/tfi";
import { TfiAngleDown } from "react-icons/tfi";

import { v4 as uuidv4 } from "uuid";
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Slider from "react-slick";
import { Fragment, useEffect, useState } from "react";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="prev tabindexlink absolute top-[50%] left-[-40px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleLeft size={20} color="#fff" />
    </button>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="next tabindexlink absolute top-[50%] right-[-40px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleRight size={20} color="#fff" />
    </button>
  );
};

import { Button } from "../Elements/Button/Button";

export const AllDocuments = ({ data, id, documents }) => {
  //console.log("###")
  //console.log(data)
  //console.log("###")
  ///c
  //console.log(documents.document);

  const [uniqueCategories, setUniqueCategories] = useState({});
  useEffect(() => {
    try {
      // Function to extract unique category names from documents
      const extractCategories = () => {
        // Create an empty set to store unique category names
        const uniqueCategorySet = new Set();
        const data = {};

        // Loop through the documents array and extract category names

        documents.document.reverse().forEach((document) => {
          if (document.category) {
            const cate = { s: [], sel: "", t: document.category };
            if (!!(document?.subcategory?.length ?? false)) {
              //console.log(document.subcategory);
              cate.s.push(document.subcategory);
            }
            uniqueCategorySet.add(document.category);
            if (data[document.category]) {
              data[document.category].s = [
                ...data[document.category].s,
                ...cate.s,
              ].filter((v, i, a) => a.indexOf(v) == i);
              return;
            }
            data[document.category] = cate;
          }
        });

        // Convert the set to an array and update the state
        const uniqueCategoryArray = Array.from(uniqueCategorySet);
        const object_map = {};
        for (const object of uniqueCategoryArray) {
          //console.log(object, data[object]);
          object_map[data[object].t] = data[object];
        }
        setUniqueCategories(object_map);
      };
      // Call the function to extract categories when documents change
      extractCategories();
    } catch (e) { }
  }, [documents]);

  //console.log(uniqueCategories);

  const [isOpen, setIsOpen] = useState(
    Array(uniqueCategories.length).fill(false) ?? false
  );

  useEffect(() => {
    const hasher = decodeURIComponent(
      window.location.hash.replaceAll("_", " ").slice(1)
    );
    console.log("hasher c: " + hasher);
    if (!uniqueCategories[hasher]) return console.log("hasher no found :(");
    const idx = Object.keys(uniqueCategories).indexOf(hasher);
    if (idx < 0) return console.log("hasher was [ ]");
    const openClone = [...isOpen];
    openClone[idx] = true;
    setIsOpen(openClone);
  }, [uniqueCategories]);
  //console.log(isOpen);
  // Function to toggle the state of a specific item
  const toggleItem = (index) => {
    const updatedIsOpen = [...isOpen];
    const keyr = Object.keys(uniqueCategories)[index];
    if ((updatedIsOpen[index] = !updatedIsOpen[index]))
      window.location.hash = keyr.replaceAll(" ", "_");
    else
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    setIsOpen(updatedIsOpen);
  };
  const select_category = (t, c) => {
    setUniqueCategories((p) => {
      if (p[t].sel == c) {
        // p[t].sel = "";
      } else {
        p[t].sel = c;
      }
      return { ...p };
    });
  };

  return (
    <>
      <section
        id={id}
        className="max-w-[100%] AllDocuments w-[100%] h-[auto] py-[25px] bg-transparent flex items-center justify-center flex-col mx-auto relative z-[2] "
      >
        {/* RENDER A CATEGORY HEADING FOR EACH UNIQUE CATEGORY */}

        {Object.entries(uniqueCategories).map(([unique, univ], index) => {
          return (
            <Fragment key={unique}>
              <div
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    toggleItem(index);
                  }
                }}
                onClick={() => toggleItem(index)}
                className="tabindexlink cursor-pointer flex flex-row my-[25px] px-[20px] py-[20px] lg:justify-start  justify-center lg:pl-[10%] items-center gap-[25px] w-[100%] bg-[#fafafa] "
              >
                <HeaderTwo
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      toggleItem(index);
                    }
                  }}
                  onClick={() => toggleItem(index)}
                  classes="!w-auto !text-[30px]"
                >
                  {unique}
                </HeaderTwo>
                {!isOpen[index] ? (
                  <TfiAngleUp size={30} color={"#000"} />
                ) : (
                  <TfiAngleDown size={30} color={"#000"} />
                )}
              </div>
              <div
                hidden={isOpen[index]}
                className={`mr-auto lg:pl-10 w-[85%] lg:flex-row flex-col mx-auto ${!isOpen[index] ? "hidden" : "flex"
                  } gap-3`}
              >
                {!!univ.s.length && (
                  <div
                    tabindex={isOpen[index] ? "0" : "-1"}
                    className={` ${"allButton" + index
                      } tabIndexLink allButton px-3 py-2 border select-none cursor-pointer bg-neutral-800 text-white`}
                    onClick={(v) => {
                      select_category(unique, "");
                      document
                        .querySelector(".allButton" + index)
                        .classList.add("bg-neutral-800", "text-white");
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        select_category(unique, "");
                        // document.querySelectorAll(".allButton").forEach((button) => {
                        //     button.classList.add("bg-neutral-800", "text-white");
                        //   });

                        document
                          .querySelector(".allButton" + index)
                          .classList.add("bg-neutral-800", "text-white");
                      }
                    }}
                  >
                    All
                  </div>
                )}

                {univ.s.map((cate) => {
                  return (
                    <div
                      tabindex={isOpen[index] ? "0" : "-1"}
                      key={uuidv4()}
                      className={`px-3 py-2 border select-none cursor-pointer ${cate == univ.sel && "bg-neutral-800 text-white "
                        }`}
                      onClick={(v) => {
                        select_category(unique, cate);

                        document
                          .querySelector(".allButton" + index)
                          .classList.remove("bg-neutral-800", "text-white");
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          select_category(unique, cate);

                          document
                            .querySelector(".allButton" + index)
                            .classList.remove("bg-neutral-800", "text-white");
                        }
                      }}
                    >
                      {cate}
                    </div>
                  );
                })}
              </div>
              <div
                className={
                  !isOpen[index]
                    ? "w-[80%] mx-auto gap-[25px] !my-[50px] slider-container flex-row hidden flex-wrap "
                    : "w-[80%] mx-auto gap-[25px] !my-[50px] slider-container flex-row flex flex-wrap "
                }
              >
                {/* RENDER THE DOCS FOR THAT CATEGORY */}

                {documents.document
                  .filter((document) => {
                    if (document.category != unique) {
                      return false;
                    }
                    if (univ.sel && document.subcategory != univ.sel) {
                      // console.log(univ.sel, )
                      return false;
                    }

                    return true;
                  })
                  .map((document) => {
                    return (
                      <div
                        key={uuidv4()}
                        className="sliderChild flex justify-start flex-col items-center w-[100%] md:w-[45%] xl:w-[30%] 2xl:w-[23%] !h-auto  text-center "
                      >
                        <div className="w-[100%] h-[100%] flex justify-start items-stretch ">
                          <div className="flex flex-col justify-around items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] py-[50px]  bg-[#FAFAFA]">
                            <h4
                              tabindex="0"
                              className="tabindexlink text-[20px] mt-[20px] font-semibold text-center mb-[20px] pt-[10px] "
                            >
                              {document.title}
                            </h4>

                            {document.file.url ? (
                              <Button
                                classes={"!mt-[15px]"}
                                url={document.file.url}
                              >
                                Download
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fragment>
          );
        })}
      </section>
    </>
  );
};
