"use client";
import Image from "next/image";
import Link from "next/link";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { Button } from "../Elements/Button/Button";
import Slider from "react-slick";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";

export const TextWithStats = ({ data, id }) => {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button
        onClick={onClick}
        aria-label="Previous Stat"
        className="prev tabindexlink  translate-y-[-50%] absolute top-[50%] left-[-40px] bg-[#1757A3] rounded-full p-[10px]"
      >
        <TfiAngleLeft size={20} color="#fff" />
      </button>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button
        onClick={onClick}
        aria-label="Next Stat"
        className="next focus:outline tabindexlink absolute top-[50%]  translate-y-[-50%] right-[-40px] bg-[#1757A3] rounded-full p-[10px]"
      >
        <TfiAngleRight size={20} color="#fff" />
      </button>
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <section
        id={id}
        className="TextWithStats max-w-[100%] w-[100%] h-[auto] pt-[75px] bg-transparent flex items-center justify-center flex-col mx-auto relative z-[2] "
      >
        {/* CIRCLES */}

        <div
          style={{ backgroundColor: `${data.circle_one_background_color}` }}
          className="z-[-1] w-[900px] h-[900px]  rounded-full absolute left-[90%] bottom-[-20%]"
        ></div>

        <div
          style={{ backgroundColor: `${data.circle_two_background_color}` }}
          className="z-[-1] w-[900px] h-[900px] rounded-full absolute right-[93%] lg:right-[90%] top-[-20%]"
        ></div>

        <HeaderTwo>{data.header}</HeaderTwo>


        {data?.body &&
          <p className="w-[90%] lg:w-[50%] mt-[50px] text-center text-[18px] mx-auto leading-relaxed">
            {data?.body}
          </p>

        }


        {data?.sub_body &&
          <p className="w-[90%] lg:w-[50%] mt-[50px] text-center text-[18px] mx-auto leading-relaxed">
            {data?.sub_body}
          </p>
        }

        <div className="container flex mx-auto gap-10 flex-col lg:flex-row justify-center items-center lg:items-start mt-[50px]">
          <div className="w-[75%] mx-auto gap-[25px] !my-[50px]">
            <Slider {...settings}>
              {data.slides.map((slide) => {
                return (
                  <div
                    key={slide.stat_text}
                    className=" !flex flex-col justify-center items-center w-[90%] lg:w-[20%]"
                  >
                    <div
                      style={{
                        backgroundColor: `${slide.stat_background_colour}`,
                      }}
                      className="mb-[50px] rounded-full h-[200px] w-[200px] bg-[#993189] flex justify-center items-center flex-col"
                    >
                      <span
                        style={{ color: `${slide.stat_text_colour}` }}
                        className="text-[30px] text-[#fff] font-semibold"
                      >
                        {slide.stat_number}
                      </span>
                      {slide.stat_figure ? (
                        <span
                          style={{ color: `${slide.stat_text_colour}` }}
                          className="text-[30px] text-[#fff] font-semibold"
                        >
                          {slide.stat_figure}
                        </span>
                      ) : null}
                    </div>
                    <p className="w-[85%] lg:w-[90%] text-[18px] text-center">
                      {slide.stat_text}
                    </p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        {data.button ? (
          <Button url={data.button_fields.button_url}>
            {data.button_fields.button_text}
          </Button>
        ) : null}
      </section>
    </>
  );
};

