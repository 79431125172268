"use client";
import { Toaster as Sonner, toast } from "sonner";
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { useEffect, useState, useCallback, createRef, useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import Link from "next/link";

import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";

export const FormBuilder = ({ data, id }) => {
  // console.log(data)

  // useEffect(() => {
  //   toast.success("Form submitted!", { duration: 34343443 });
  // }, []);
  //RECAPTCHA CODEe
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [currentUrl, setCurrentUrl] = useState("");

  const path = usePathname();

  const [inputWidth, setInputWidth] = useState("45%");

  // console.log(data)
  const [captchaPassed, setCaptchaPassed] = useState(false);

  useEffect(() => {
    const recaptchaCheckbox = document.querySelector(".recaptcha-checkbox");
  });

  // Update the 'currentUrl' state when the route changes
  useEffect(() => {
    setCurrentUrl(path);
  }, [path]);

  const listOfFieldNames = [];

  useEffect(() => {
    // console.log(listOfFieldNames)
  }, [listOfFieldNames]);

  const [send, setSend] = useState(false);

  const recaptchaRef = useRef(null);

  async function handleSubmit(e) {
    e.preventDefault();

    const token = recaptchaRef.current.getValue();

    if (token) {
      const listOfUpdatedFields = [];

      //add email recipients to array
      listOfUpdatedFields.push({
        emailList: data.notifications_emails_to,
      });

      //add email subject to array
      listOfUpdatedFields.push({
        emailSubject: data.email_subject,
      });

      //add current url to array
      listOfUpdatedFields.push({
        currentUrl: currentUrl,
      });

      let clientEmailField = "";

      try {
        if (data.client_notification_field) {
          clientEmailField =
            document.getElementById(data.client_notification_field).value ?? "";
        }
      } catch (e) { }

      //add client email to array
      listOfUpdatedFields.push({
        clientEmail: clientEmailField,
      });

      for (const field of listOfFieldNames) {
        const id = field.FieldName; // Get the ID (FieldName) from the object
        const placeholder = field.FieldPlaceholder; // Get the ID (FieldName) from the object

        if (field.FieldType === "RadioButton") {
          const value = document.querySelector(
            `input[name="${field.GroupName}"]:checked`,
          ).value;

          listOfUpdatedFields.push({ id, placeholder, value }); // Add the ID and value to the new array
        } else if (field.FieldType === "CheckBox") {
          const checkboxes = document.querySelectorAll(
            `input[name="${field.GroupName}"]:checked`,
          );
          const values = Array.from(checkboxes).map(
            (checkbox) => checkbox.value,
          );
          const combinedValue = values.join(", ");

          listOfUpdatedFields.push({ id, placeholder, value: combinedValue });
        } else {
          const value = document.getElementById(id).value; // Get the value by ID
          const fieldAccess = document.getElementById(id); // Get the value by ID

          listOfUpdatedFields.push({ id, placeholder, value }); // Add the ID and value to the new array
          //RESET ALL FIELDS
          fieldAccess.value = "";
        }
      }

      //listOfUpdatedFields = the fieldName, fieldPlaceHolder and fieldValue so we can use it to process the forms.
      // console.log(listOfUpdatedFields);

      //PROCESS FORM HERE

      const JSONdata = JSON.stringify(listOfUpdatedFields);

      // console.log(JSONdata)

      const endpoint = "/api/email";

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSONdata,
      };

      async function submitData(endpoint, options) {
        const response = await fetch(endpoint, options);

        console.log("okay!");
        if (response.ok) {
          toast.success("Form submitted!");
        } else {
          toast.error("Form not submitted...");
        }
      }

      setSend(true);
      submitData(endpoint, options);
      setSend(false);
      recaptchaRef.current.reset();
    } else {
      toast.error("Form not submitted...");
    }
  }

  const [isRequired, setIsRequired] = useState(false);

  return (
    <>
      <p id="captchaText" className="hidden"></p>
      <Sonner
        className="toaster group"
        toastOptions={{
          classNames: {
            toast:
              "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
            description: "group-[.toast]:text-muted-foreground",
            actionButton:
              "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
            cancelButton:
              "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
          },
        }}
      />

      <section id={id} className="FormBuilder  relative my-[100px]">
        <HeaderTwo classes="text-center !w-[100%] text-3xl lg:!text-5xl mb-[50px] px-[25px] ">
          {data.form_title}
        </HeaderTwo>

        {data.form_body ? (
          <p className="leading-[2] w-[85%] mx-auto lg:w-[50%] my-[50px] text-center ">
            {data.form_body}
          </p>
        ) : null}

        <form
          onSubmit={handleSubmit}
          className="formBuilderForm w-[90%] md:w-[700px] mx-auto flex flex-col items-end gap-[20px]  "
        >
          <div className="w-[100%] flex flex-row flex-wrap justify-between gap-y-[20px] ">
            {data.fields.map((field) => {
              // console.log(field.choose_a_field)

              const data = field.choose_a_field;

              switch (field.choose_a_field) {
                // T E X T  F I E L D
                case "Text Field":
                  listOfFieldNames.push({
                    FieldName: field.text_field.field_name,
                    FieldPlaceholder: field.text_field.placeholder,
                    FieldType: "TextField",
                  });

                  return (
                    <div
                      key={field.text_field.field_name}
                      className={
                        field.text_field.size === "100%"
                          ? "w-[100%] flex flex-col gap-y-[20px]"
                          : "w-[47%] flex flex-col gap-y-[20px]"
                      }
                    >
                      {field.text_field.header ? (
                        <h5>{field.text_field.header}</h5>
                      ) : null}
                      {field.text_field.body ? (
                        <ReactMarkdown
                          className="formItem"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {field.text_field.body.replace(/\n/gi, "<br/> \n")}
                        </ReactMarkdown>
                      ) : null}

                      <input
                        aria-label={
                          field?.text_field?.placeholder ?? "Text Field"
                        }
                        key={field.text_field.field_name}
                        required={
                          field.text_field.required === "Yes" ? true : null
                        }
                        placeholder={
                          field.text_field.required === "Yes"
                            ? field.text_field.placeholder + " *"
                            : field.text_field.placeholder
                        }
                        id={field.text_field.field_name}
                        className={
                          field.text_field.size === "100%"
                            ? "w-[100%] box-border !border-[#949494] border-[0.5px] lg:mr-[20px]"
                            : "w-[100%] box-border !border-[#949494] border-[0.5px] lg:mr-[20px]"
                        }
                        type="text"
                        name={field.text_field.field_name}
                      />
                    </div>
                  );
                  break;

                // E M A I L  A D D R E S S
                case "Email Address":
                  listOfFieldNames.push({
                    FieldName: field.email_address.field_name,
                    FieldPlaceholder: field.email_address.placeholder,
                    FieldType: "EmailAddress",
                  });

                  //append * to placeholder

                  return (
                    <div
                      key={field.email_address.field_name}
                      className={
                        field.email_address.size === "100%"
                          ? "w-[100%]  flex flex-col gap-y-[20px]"
                          : "w-[47%] flex flex-col gap-y-[20px]"
                      }
                    >
                      {field.email_address.header ? (
                        <h5>{field.email_address.header}</h5>
                      ) : null}
                      {field.email_address.body ? (
                        <ReactMarkdown
                          className="formItem"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {field.email_address.body.replace(/\n/gi, "<br/> \n")}
                        </ReactMarkdown>
                      ) : null}

                      <input
                        aria-label={
                          field?.email_address?.placeholder ?? "Email Field"
                        }
                        required={
                          field.email_address.required === "Yes" ? true : null
                        }
                        placeholder={
                          field.email_address.required === "Yes"
                            ? field.email_address.placeholder + " *"
                            : field.email_address.placeholder
                        }
                        key={field.email_address.field_name}
                        className={
                          field.email_address.size === "100%"
                            ? "w-[100%] box-border !border-[#949494] border-[0.5px] lg:mr-[20px]"
                            : "w-[100%] box-border !border-[#949494] border-[0.5px] lg:mr-[20px]"
                        }
                        type="email"
                        id={field.email_address.field_name}
                        name={field.email_address.field_name}
                      />
                    </div>
                  );
                  break;

                // P H O N E  N U M B E R
                case "Phone Number":
                  listOfFieldNames.push({
                    FieldName: field.phone_number.field_name,
                    FieldPlaceholder: field.phone_number.placeholder,
                    FieldType: "PhoneNumber",
                  });

                  return (
                    <div
                      key={field.phone_number.field_name}
                      className={
                        field.phone_number.size === "100%"
                          ? "w-[100%]  flex flex-col gap-y-[20px]"
                          : "w-[47%] flex flex-col gap-y-[20px]"
                      }
                    >
                      {field.phone_number.header ? (
                        <h5>{field.phone_number.header}</h5>
                      ) : null}
                      {field.phone_number.body ? (
                        <ReactMarkdown
                          className="formItem"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {field.phone_number.body.replace(/\n/gi, "<br/> \n")}
                        </ReactMarkdown>
                      ) : null}

                      <input
                        aria-label={
                          field?.phone_number?.placeholder ?? "Phone Field"
                        }
                        required={
                          field.phone_number.required === "Yes" ? true : null
                        }
                        placeholder={
                          field.phone_number.required === "Yes"
                            ? field.phone_number.placeholder + " *"
                            : field.phone_number.placeholder
                        }
                        key={field.phone_number.field_name}
                        className={
                          field.phone_number.size === "100%"
                            ? "w-[100%] box-border !border-[#949494] border-[0.5px] lg:mr-[20px]"
                            : "w-[100%] box-border !border-[#949494] border-[0.5px] lg:mr-[20px]"
                        }
                        type="tel"
                        id={field.phone_number.field_name}
                        name={field.phone_number.field_name}
                      />
                    </div>
                  );
                  break;

                // T E X T  A R E A
                case "Text Area":
                  listOfFieldNames.push({
                    FieldName: field.text_area.field_name,
                    FieldPlaceholder: field.text_area.placeholder,
                    FieldType: "TextArea",
                  });

                  return (
                    <div key={field.text_area.field_name}>
                      {field.text_area.header ? (
                        <h5>{field.text_area.header}</h5>
                      ) : null}
                      {field.text_area.body ? (
                        <ReactMarkdown
                          className="formItem"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {field.text_area.body.replace(/\n/gi, "<br/> \n")}
                        </ReactMarkdown>
                      ) : null}

                      <textarea
                        aria-label={
                          field?.text_area?.placeholder ?? "Text Area Field"
                        }
                        required={
                          field.text_area.required === "Yes" ? true : null
                        }
                        placeholder={
                          field.text_area.required === "Yes"
                            ? field.text_area.placeholder + " *"
                            : field.text_area.placeholder
                        }
                        key={field.text_area.field_name}
                        className={
                          "w-[100%] box-border !border-[#949494] border-[0.5px] "
                        }
                        type="tel"
                        id={field.text_area.field_name}
                        name={field.text_area.field_name}
                      ></textarea>
                    </div>
                  );
                  break;

                // L I N E  B R E A K

                case "Line Break":
                  // console.log(field.line_break);
                  return (
                    <div
                      key={field.line_break}
                      style={{ backgroundColor: `${field.line_break}` }}
                      className="h-[2px] w-[100%] my-[15px] "
                    ></div>
                  );
                  break;

                // S E L E C T  B O X
                case "Select Box":
                  listOfFieldNames.push({
                    FieldName: field.select_box.field_name,
                    FieldPlaceholder: field.select_box.placeholder,
                    FieldType: "SelectBox",
                  });

                  return (
                    <div key={field.select_box.field_name}>
                      {field.select_box.header ? (
                        <h5>{field.select_box.header}</h5>
                      ) : null}
                      {field.select_box.body ? (
                        <ReactMarkdown
                          className="formItem"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {field.select_box.body.replace(/\n/gi, "<br/> \n")}
                        </ReactMarkdown>
                      ) : null}

                      <select
                        aria-label={
                          field?.select_box?.placeholder ?? "Select Box Field"
                        }
                        defaultValue="placeholder"
                        required={
                          field.select_box.required === "Yes" ? true : null
                        }
                        key={field.select_box.field_name}
                        className={
                          "w-[100%] box-border !border-[#949494] border-[0.5px]"
                        }
                        type="tel"
                        id={field.select_box.field_name}
                        name={field.select_box.field_name}
                      >
                        <option value="placeholder" disabled>
                          {field.select_box.placeholder}
                          {field.select_box.required == "Yes" ? " *" : null}
                        </option>
                        {/* MAP OUT OPTIONS HERE  */}
                        {field.select_box.option.map((option, index) => (
                          <option key={index} value={option.option_placeholder}>
                            {option.option_placeholder}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                  break;

                // R A D I O  B U T T O N
                case "Radio Button":
                  listOfFieldNames.push({
                    FieldName: field.radio_button.field_name,
                    FieldPlaceholder: field.radio_button.placeholder,
                    FieldType: "RadioButton",
                    GroupName: field.radio_button.field_name,
                  });

                  return (
                    <div key={field.radio_button.field_name}>
                      <div className="w-[100%] flex flex-row gap-x-[25px] flex-wrap">
                        <p className="font-semibold w-[100%] ">
                          {field.radio_button.placeholder}
                          {field.radio_button.required === "Yes" ? " *" : null}
                        </p>

                        {field.radio_button.radio.map((option, index) => (
                          <div
                            className="flex flex-row justify-center items-center gap-[20px]"
                            key={index}
                          >
                            <input
                              aria-label={
                                field?.radio_button?.placeholder ??
                                "Radio Button Field"
                              }
                              required={
                                field.radio_button.required === "Yes"
                                  ? true
                                  : null
                              }
                              type="radio"
                              id={option.radio_label}
                              name={field.radio_button.field_name}
                              value={option.radio_label}
                            />
                            <label htmlFor={option.radio_label}>
                              {option.radio_label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                  break;

                // C H E C K B O X
                case "Check Box":
                  listOfFieldNames.push({
                    FieldName: field.check_box.field_name,
                    FieldPlaceholder: field.check_box.placeholder,
                    FieldType: "CheckBox",
                    GroupName: field.check_box.field_name,
                  });

                  return (
                    <div key={field.check_box.field_name}>
                      <div className="w-[100%] flex flex-row gap-x-[25px] flex-wrap">
                        <p className="font-semibold w-[100%] ">
                          {field.check_box.placeholder}{" "}
                        </p>
                        {field.check_box.checkboxes.map((option, index) => (
                          <div
                            className="flex flex-row justify-center items-center gap-[20px]"
                            key={index}
                          >
                            <input
                              aria-label={
                                field?.check_box?.placeholder ??
                                "Check Box Field"
                              }
                              type="checkbox"
                              id={option.checkbox_label}
                              name={field.check_box.field_name}
                              value={option.checkbox_label}
                            />
                            <label htmlFor={option.checkbox_label}>
                              {option.checkbox_label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                  break;

                // C O U N T R I E S  D R O P  D O W N
                case "Countries List":
                  listOfFieldNames.push({
                    FieldName: field.countries_list.field_name,
                    FieldPlaceholder: field.countries_list.placeholder,
                    FieldType: "CountriesList",
                    GroupName: field.countries_list.field_name,
                  });

                  return (
                    <>
                      <select
                        aria-label={
                          field?.countries_list?.placeholder ??
                          "Countries List Field"
                        }
                        required={
                          field.countries_list.required === "Yes" ? true : null
                        }
                        className={
                          "w-[100%] box-border !border-[#949494] border-[0.5px]"
                        }
                        key={field.countries_list.field_name}
                        id={field.countries_list.field_name}
                        name={field.countries_list.field_name}
                      >
                        <option>Select a Country</option>
                        <option value="United Kingdom">United Kingdom</option>

                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Aland Islands">Aland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire, Sint Eustatius and Saba">
                          Bonaire, Sint Eustatius and Saba
                        </option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, Democratic Republic of the Congo">
                          Congo, Democratic Republic of the Congo
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curacao">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic Peoples Republic of">
                          Korea, Democratic Peoples Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kosovo">Kosovo</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao Peoples Democratic Republic">
                          Lao Peoples Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, the Former Yugoslav Republic of">
                          Macedonia, the Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthelemy">
                          Saint Barthelemy
                        </option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Martin">Saint Martin</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and the Grenadines">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Serbia and Montenegro">
                          Serbia and Montenegro
                        </option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Sint Maarten">Sint Maarten</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan, Province of China">
                          Taiwan, Province of China
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.s.">
                          Virgin Islands, U.s.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </>
                  );
                  break;
              }
            })}
          </div>

          <div className="flex flex-row w-[100%] items-center justify-end">
            <p className="mr-[10px]">
              I have read and agree to the{" "}
              <Link className="text-[#1875A0] font-bold" href="/privacy-policy">
                privacy policy
              </Link>
              .
            </p>
            <input
              aria-label="Consent Checkbox"
              type="checkbox"
              id="consent"
              name="consent"
              required
              value="privacyConsent"
            />
          </div>

          <ReCAPTCHA
            sitekey="6LcehJ8kAAAAAIgVCtwTEexY6fbTscvKQ3FmmTNN"
            ref={recaptchaRef}
          />
          <input
            className="cursor-pointer lg:mr-[20px] w-[150px] mt-[25px] !z-[15]"
            type="submit"
            value={data.button_value}
          />
        </form>
      </section>
    </>
  );
};
