"use client";

import Slider from "react-slick";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import rehypeRaw from 'rehype-raw'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";

export const ImageContentSlider = ({ data, id }) => {


  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button aria-label="Previous Image & Content Slide" onClick={onClick} className="prev tabindexlink z-[2] absolute top-[50%] left-[-50px] bg-[#1757A3] rounded-full p-[10px]">
        <TfiAngleLeft size={20} color="#fff" />
      </button>
    )
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button aria-label="Next Image & Content Slide" onClick={onClick} className="next z-[2] tabindexlink absolute top-[50%] right-[-50px] bg-[#1757A3] rounded-full p-[10px]">
        <TfiAngleRight size={20} color="#fff" />
      </button>
    )
  }


  const settings = {
    useTransform: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {

        breakpoint: 1399,
        settings: {
          useTransform: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          useTransform: false,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,

        }
      },
    ]
  };


  return (
    <>

      <section id={id} className=" ImageContentSlider w-[80%]  mt-[75px] mx-auto">


        {data.header &&
          <HeaderTwo classes="!text-center mx-auto">{data.header}</HeaderTwo>
        }

        <div className="py-[50px] h-[100%]">
          <Slider {...settings}>


            {data.images.map((section) => {

              return (

                <div key={section.image.url} className="sliderChild w-[50%] !flex justify-center items-center ">
                  <div className="bg-[#fafafa]  rounded-[50px] w-[95%] h-[100%] py-[50px] flex flex-col justify-start items-center">


                    {section.image.url ? <img className="aspect-[5/3] object-cover w-[75%]" alt={section.image.alt} src={section.image.url} />
                      : null}

                    <ReactMarkdown className="text-[16px] w-[75%] py-[25px]" rehypePlugins={[rehypeRaw]}>
                      {section.body.replace(/\n/gi, "<li className='list-none mt-[10px]'></li>")}
                    </ReactMarkdown>

                  </div>
                </div>


              )
            })}


          </Slider>
        </div>

      </section>

    </>
  )
}
