import React, { useEffect } from "react";
import styles from "./Pagination.module.css";

const Pagination = ({
  handlePageChange,
  numberOfArticles,
  totalPages,
  currentPage,
}) => {
  const paginationArr = Array.from({ length: totalPages }, (_, i) => i + 1);

  const getDisplayedPages = () => {
    const currentPageNum = Number(currentPage);
    const pagesToShow = 5;

    if (totalPages <= pagesToShow) {
      return paginationArr;
    }

    let startPage = Math.max(currentPageNum - Math.floor(pagesToShow / 2), 1);
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < pagesToShow) {
      startPage = endPage - pagesToShow + 1;
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  };

  const goToFirstPage = () => {
    handlePageChange(1);
    window.scrollTo({ top: 0 });
  };

  const goToLastPage = () => {
    handlePageChange(totalPages);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className={`w-[100%] pagination ${styles.pagination}`}>
      <div className={`${styles.flex}`}>
        {currentPage > 1 && (
          <div
            tabIndex="0"
            className={`pagination-item ${styles.item}`}
            onClick={goToFirstPage}
            onKeyPress={(event) => {
              if (event.key === "Enter" || event.keyCode === 13) {
                goToFirstPage();
              }
            }}
          >
            First
          </div>
        )}
        {getDisplayedPages().map((paginationItem) => {
          const active = paginationItem === currentPage ? "activePag" : "";
          return (
            <div
              tabIndex="0"
              key={`pagination-${paginationItem}`}
              className={`pagination-item pagination-item--${paginationItem} ${styles.item} ${active}`}
              onClick={() => {
                handlePageChange(paginationItem);
                window.scrollTo({ top: 0 });
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter" || event.keyCode === 13) {
                  handlePageChange(paginationItem);
                  window.scrollTo({ top: 0 });
                }
              }}
            >
              {paginationItem}
            </div>
          );
        })}
        {currentPage + 1 == totalPages && (
          <div
            tabIndex="0"
            className={`pagination-item ${styles.item}`}
            onClick={goToLastPage}
            onKeyPress={(event) => {
              if (event.key === "Enter" || event.keyCode === 13) {
                goToLastPage();
              }
            }}
          >
            Laaaaaaast {currentPage} {totalPages}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
