"use client";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import Slider from "react-slick";
import Image from "next/image";
import { Button } from "../Elements/Button/Button";
import rehypeRaw from 'rehype-raw'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
export const HalfTextHalfSlider = ({ data, id }) => {



  // gsap.registerPlugin(ScrollTrigger); 
  //console.log("#####two images######")

  //console.log(data)
  //console.log("#####two images######")


  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button onClick={onClick} className="prev tabindexlink z-[2] absolute top-[50%] left-[100px] bg-[#1757A3] rounded-full p-[10px]">
        <TfiAngleLeft size={20} color="#fff" />
      </button>
    )
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button onClick={onClick} className="next z-[2] tabindexlink absolute top-[50%] right-[100px] bg-[#1757A3] rounded-full p-[10px]">
        <TfiAngleRight size={20} color="#fff" />
      </button>
    )
  }

  const settings = {
    useTransform: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {

        breakpoint: 1399,
        settings: {
          useTransform: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          useTransform: false,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,

        }
      },
    ]
  };

  // Format URLs for aria labels
  const formatted = url => {
    // Remove trailing slash if it exists
    const cleanedUrl = url.replace(/\/$/, ''); // Remove trailing slash

    // Split the URL and take the last part
    const part = cleanedUrl.split('/').pop();

    // Strip the file extension
    const partWithoutExtension = part.replace(/\.[^/.]+$/, ''); // Remove file extension

    // Replace hyphens with spaces and capitalize each word
    return partWithoutExtension
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join back into a string
  };



  return (
    <section id={id} className="max-w-[100%]">
      <section className="HalfTextHalfSlider w-[100%] flex flex-col lg:flex-row px-[5%] pt-[75px] relative">

        {/* TEXT */}
        <div style={{ order: data?.layout === "textLeftSliderRight" ? "0" : "1" }} className="w-[100%] lg:w-[40%] h-[auto] flex items-center lg:items-center flex-col z-1">
          <div className="w-[80%] xl:w-[80%] 2xl:w-[70%] flex flex-col justify-center items-start">
            <HeaderTwo classes="!text-left !w-[100%]">{data?.header}</HeaderTwo>
            {/* <p className="text-[18px] pt-[50px] text-left">{data?.body}</p> */}

            <div className="pt-[50px]">
              <ReactMarkdown className="[&>*]:!text-[18px]" rehypePlugins={[rehypeRaw]}>
                {data?.body}
              </ReactMarkdown>
            </div>

            {data?.add_button &&

              <Button aria={formatted(data?.button_url)} classes="!flex" url={data?.button_url}>
                {data?.button_label}
              </Button>

            }
          </div>
        </div>


        {/* SLIDER */}
        <div style={{ order: data?.layout === "textLeftSliderRight" ? "1" : "0" }} className="w-[100%] mt-[50px] lg:mt-[0px] lg:w-[55%] h-[auto] pb-[50px] z-[1]">
          <Slider {...settings}>

            {data.slides.map((sliderData) => {
              return (
                <div key={sliderData.image.url} className="relative h-[500px] w-[90%] lg:w-[100%] !flex justify-center items-start ">
                  <img className="w-[90%] lg:w-[500px] lg:h-[400px] lg:object-cover" src={sliderData.image.url} alt={sliderData.image.alt} height={500} width={500} />

                  <div className="bg-[#F7F7F7] w-[90%] lg:w-[40%] rounded-[20px] p-[20px] absolute z-[5] bottom-[0%]  lg:bottom-[0px] lg:right-[15%]">
                    <h5 className="font-bold text-[20px] mb-[10px]">{sliderData.header}</h5>
                    <p className="!text-[18px] leading-[1.4] font-[400]">{sliderData.body}</p>

                  </div>
                </div>
              )
            })
            }

          </Slider>
        </div>
      </section>
    </section>
  )
}
