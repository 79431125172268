"use client";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo"
import rehypeRaw from 'rehype-raw'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Button } from "../Elements/Button/Button";
import { useEffect, useState, useRef } from "react";
export const TextandDoubleBodyFull = ({ data, id }) => {

  // console.log("$$$$$$$$$$$$$$")
  // console.log(data)
  // console.log("$$$$$$$$$$$$$$")

  const [maxButtonHeight, setMaxButtonHeight] = useState(0);

  // Format URLs for aria labels
  const formatted = url => {
    // Remove trailing slash if it exists
    const cleanedUrl = url.replace(/\/$/, ''); // Remove trailing slash

    // Split the URL and take the last part
    const part = cleanedUrl.split('/').pop();

    // Replace hyphens with spaces and capitalize each word
    return part
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join back into a string
  };


  useEffect(() => {

    // Select all elements with the ".infoSliderButton" class
    var elements = document.querySelectorAll(".doubleButton");
    var parent = document.querySelectorAll(".buttonParent");

    // Initialize a variable to keep track of the maximum height
    var maxHeight = 0;

    // Iterate through the selected elements
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      // Get the height of the current element
      var elementHeight = element.clientHeight; // Use clientHeight to account for padding

      // Update the maxHeight if the current element's height is greater
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    // Set the height of all elements to the maxHeight
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      var maxHeightMinusOffset = maxHeight - 25;

      // Set the height of the current element to maxHeight
      element.style.height = maxHeight + "px";


    }
    if (window.innerWidth > 1000) {
      parent.forEach((parent) => {
        parent.style.marginBottom = maxHeightMinusOffset + "px";
      });
    }

    setMaxButtonHeight(maxHeight)


  }, [])



  return (
    <>
      <section id={id} className={data.circle_type == "Quarter" ? "TextandDoubleBodyFull mt-[75px] lg:mt-[75px] flex justify-center relative overflow-hidden" : "TextandDoubleBodyFull mt-[75px] lg:mt-[75px] flex justify-center relative"}>


        {!data.hide_circle ?
          <>
            {data.circle_type == "Half" ?
              <div style={{ backgroundColor: `${data.circle_one_background_color}` }} className={data.circle_one_position === "Left" ? "w-[700px] h-[700px]  rounded-full absolute right-[85%] top-[50%] translate-y-[-50%]" : "w-[700px] h-[700px]  rounded-full absolute left-[85%] top-[50%] translate-y-[-50%]"}></div>

              : null}

            {data.circle_type == "Segment" ?
              <div style={{ backgroundColor: `${data.circle_one_background_color}` }} className={data.circle_one_position === "Left" ? "w-[700px] h-[700px]  rounded-full absolute right-[95%] top-[50%] translate-y-[-50%]" : "w-[700px] h-[700px]  rounded-full absolute left-[95%] top-[50%] translate-y-[-50%]"}></div>

              : null}

            {data.circle_type == "Quarter" ?
              <div style={{ backgroundColor: `${data.circle_one_background_color}` }} className={data.circle_one_position === "Left" ? "z-[-2] aspect-square h-[200%]  rounded-full absolute bottom-[0%] right-[80%] translate-y-[-0%]" : "z-[-2] aspect-square h-[200%]  rounded-full absolute bottom-[0%] left-[80%] translate-y-[-0%]"}></div>

              : null}

          </>
          : null}


        <div className="w-[90%] sm:w-[80%] h-[100%] flex justify-end">
          <div className="bg-[#F7F7F7] z-[1] rounded-[75px] w-[100%] py-[75px] px-[25px] sm:py-[75px] sm:px-[75px] relative flex justify-center flex-col items-center">

            {data.header ?
              <HeaderTwo classes={"!w-[100%] mb-[25px] !text-left"}>{data.header}</HeaderTwo>
              : null}

            {/*SINGLE COoL*/}
            {data.single_col_body ?
              <div className="w-[100%] flex flex-col lg:flex-row justify-around gap-[50px] mb-[25px]">

                <span className="text-[18px] w-[100%] lg:w-[100%] text-left flex flex-col justify-start ">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {data.single_col_body.replace(/\n/gi, "<li className='list-none mt-[10px]'></li>")}
                  </ReactMarkdown>

                </span>

              </div>

              : null}


            {/*TWO COL*/}
            <div className={data.button_one !== false || data.button_two !== false || data.button_center !== false ? "buttonParent w-[100%] flex flex-col lg:flex-row justify-around gap-[50px] mb-[25px] lg:mb-[95px]" : "w-[100%] flex flex-col lg:flex-row justify-around gap-[50px]"}>
              <span className="text-[18px] w-[100%] lg:w-[50%] text-left flex flex-col justify-start ">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {data.body.replace(/\n/gi, "<li className='list-none mt-[10px]'></li>")}
                </ReactMarkdown>

                <div className="!flex flex-wrap flex-row gap-[25px] lg:justify-start justify-center lg:absolute lg:bottom-[50px] w-[100%] lg:w-[45%]">
                  {data.button_one !== false ?
                    <>
                      {data.button_left_two == false ?
                        <Button aria={formatted(data?.button_one_url)} innerClasses="flex !mt-[0px] justify-center items-center" classes="doubleButton !flex items-center justify-center lg:!max-w-[75%]" url={data.button_one_url}>{data.button_one_text}</Button>
                        :
                        <Button aria={formatted(data?.button_one_url)} innerClasses="flex justify-center items-center" classes="doubleButton !flex items-center justify-center lg:!max-w-[45%]" url={data.button_one_url}>{data.button_one_text}</Button>
                      }
                    </>
                    : null}


                  {data.button_left_two !== false ?
                    <>
                      {data.button_one == false ?
                        <Button aria={formatted(data?.button_left_two_url)} innerClasses="flex justify-center items-center" classes="doubleButton !flex lg:!max-w-[75%]" url={data.button_left_two_url}>{data.button_left_two_text}</Button>
                        :
                        <Button aria={formatted(data?.button_left_two_url)} innerClasses="flex justify-center items-center" classes="doubleButton !flex lg:!max-w-[45%]" url={data.button_left_two_url}>{data.button_left_two_text}</Button>
                      }
                    </>
                    : null}
                </div>
              </span>

              <span className="text-[18px] w-[100%] lg:w-[50%] text-left flex flex-col justify-start ">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {data.body_2.replace(/\n/gi, "<li className='list-none mt-[10px]'></li>")}
                </ReactMarkdown>

                <div className="!flex flex-wrap flex-row gap-[25px] lg:justify-start justify-center lg:absolute lg:bottom-[50px] w-[100%] lg:w-[100%]">
                  {data.button_two !== false ?
                    <>
                      {data.button_right_two == false ?
                        <Button aria={formatted(data?.button_two_url)} innerClasses="flex !mt-[0px] justify-center items-center" classes="doubleButton !flex lg:!max-w-[75%]" url={data.button_two_url}>{data.button_two_text}</Button>
                        :
                        <Button aria={formatted(data?.button_two_url)} innerClasses="flex justify-center items-center" classes="doubleButton !flex lg:!max-w-[45%]" url={data.button_two_url}>{data.button_two_text}</Button>
                      }


                    </>
                    : null}


                  {data.button_right_two !== false ?
                    <>

                      {data.button_two == false ?
                        <Button aria={formatted(data?.button_right_two_url)} innerClasses="flex justify-center items-center" classes="doubleButton !flex lg:!max-w-[75%]" url={data.button_right_two_url}>{data.button_right_two_text}</Button>
                        :
                        <Button aria={formatted(data?.button_right_two_url)} innerClasses="flex justify-center items-center" classes="doubleButton !flex lg:!max-w-[45%]" url={data.button_right_two_url}>{data.button_right_two_text}</Button>
                      }

                    </>
                    : null}


                </div>


              </span>

            </div>


            {data.button_center !== false ?

              <Button aria={formatted(data?.button_center_url)} classes="!flex mt-[0px]" url={data.button_center_url}>{data.button_center_text}</Button>

              : null}


          </div>
        </div>
      </section>
    </>
  )
}
