"use client";

import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// import {gsap} from 'gsap'
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import Slider from "react-slick";
import Image from "next/image";
import { v4 as uuidv4 } from 'uuid';

export const DoubleQuoteSlider = ({ data, id }) => {
  // gsap.registerPlugin(ScrollTrigger); 
  //console.log("#####two images######")

  //console.log(data)
  //console.log("#####two images######")


  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button onClick={onClick} className="prev tabindexlink z-[2] absolute top-[50%] left-[100px] bg-[#1757A3] rounded-full p-[10px]">
        <TfiAngleLeft size={20} color="#fff" />
      </button>
    )
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <button onClick={onClick} className="next z-[2] tabindexlink absolute top-[50%] right-[100px] bg-[#1757A3] rounded-full p-[10px]">
        <TfiAngleRight size={20} color="#fff" />
      </button>
    )
  }

  const settings = {
    useTransform: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {

        breakpoint: 1399,
        settings: {
          useTransform: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          useTransform: false,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,

        }
      },
    ]
  };



  return (
    <section id={id} className="max-w-[100%]">
      <section className="DoubleTextSlider w-[100%] flex  flex-col py-[75px] relative">

        <div className="w-[100%] lg:w-[100%] h-[auto] flex items-center  justify-center flex-col z-1">
          <div className="w-[80%] xl:w-[80%] 2xl:w-[70%] flex flex-col justify-center items-center">
            {data.header ?
              <HeaderTwo classes="text-center !w-[100%]">{data.header}</HeaderTwo>
              : null}
            {data.body ?
              <p className="text-[18px] pt-[50px] text-center w-[90%] lg:w-[60%] mb-[50px]">{data.body}</p>
              : null}
          </div>
        </div>

        <div className="w-[100%] h-[100%] mt-[50px] lg:mt-[0px] lg:w-[100%]  pb-[50px] z-[1]">
          <Slider {...settings}>

            {data.slides.map((sliderData) => {
              return (
                <div key={uuidv4()} className="relative h-[100%] lg:h-[500px] w-[90%] lg:w-[100%] !flex flex-col justify-center items-center py-[20px] ">

                  {/* QUOTE 1 */}


                  {sliderData.quote_1 ?
                    <div className="bg-[#F7F7F7] mb-[20px] lg:mb-[0px] w-[90%] lg:w-[20%] rounded-[20px] p-[20px] relative lg:absolute z-[5] lg:top-[0%] lg:left-[20%] lg:mt-[0px] mt-[25px]">
                      <p className="!text-[18px] leading-[1.4] font-[400]">{sliderData.quote_1}</p>
                    </div>
                    : null}


                  <img className="lg:h-[400px] lg:w-[500px] object-cover " src={sliderData.image.url} alt={sliderData.image.alt} height={500} width={500} />

                  {/* QUOTE 2 */}
                  {sliderData.quote_2 ?
                    <div className="bg-[#F7F7F7] w-[90%] lg:w-[20%] rounded-[20px] p-[20px] relative lg:absolute z-[5] lg:bottom-[0%] lg:right-[20%] lg:mt-[0px] mt-[25px]">
                      <p className="!text-[18px] leading-[1.4] font-[400]">{sliderData.quote_2}</p>
                    </div>

                    : null}
                </div>
              )
            })
            }

          </Slider>
        </div>
      </section>
    </section>
  )
}
