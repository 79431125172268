"use client";
import { useCookies, CookiesProvider } from "react-cookie";
import Script from "next/script";
import { useEffect, useState } from "react";
import Link from "next/link";
import styles from "../components/CookieBar/CookieBar.module.css";

export const CookieBanner = ({ gtag }: { gtag: string }) => {

  const gtag_script = `
  (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KJZT234')`;
  
  //use states for cookies
  const [consent, setConsent] = useState(false);
  const [showCookieBar, setShowCookieBar] = useState(false);
  const [cookies, setCookie] = useCookies(["CookieConsent"]);

  useEffect(() => {
    if (cookies && cookies.CookieConsent !== undefined) {
      //show cookie bar
      setShowCookieBar(false);
      setConsent(true);
    } else {
      setShowCookieBar(true);
      setConsent(false);
    }
  }, [cookies]);

  const acceptCookie = () => {
    const newClicked = true;
    //28 day expiration date
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 28);
    setShowCookieBar(false);
    setCookie("CookieConsent", true, { path: "/", expires: expirationDate });
    setConsent(true);
  };

  const declineCookie = () => {
    const newClicked = true;
    //28 day expiration date
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 28);
    setShowCookieBar(false);
    setCookie("CookieConsent", false, { path: "/", expires: expirationDate });
    setConsent(false);
  };

  return (
    <>
      <noscript>
        <iframe
          title="google tag manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-KJZT234"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <Script
        id="1"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`}
      />


<Script id="98">{gtag_script}</Script>


      {consent === true ? (
        <>
          {/* I N S E R T   Y O U R  S C R I P T S  H E R E  */}

          <Script id="2" strategy="lazyOnload">
            {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${gtag}', {
                        page_path: window.location.pathname,
                        });
                    `}
          </Script>

          <Script
            id="3"
            src="https://cdn.userway.org/widget.js"
            data-account="jncTi80bEE"
          />

          <Script id="4">
            {`
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '339666730667756');
fbq('track', 'PageView');
`}
          </Script>
        </>
      ) : null}

      {showCookieBar && (
        <>
          <div className={`${styles.lcr__overlay}`} />
          <div className={`${styles.lcr__cookiebar}`}>
            <h3 tabIndex={showCookieBar ? 0 : -1} className="text-center">
              Cookie Notice
            </h3>
            <p tabIndex={showCookieBar ? 0 : -1}>
              This website uses cookies to analyse traffic and remember your
              website choices. You can read more about our privacy practices in
              our{" "}
              <Link tabIndex={showCookieBar ? 0 : -1} href="/privacy-policy">
                privacy & cookie policy here.
              </Link>
            </p>

            <div className={`${styles.lcr__cookiebuttons}`}>
              <span
                tabIndex={showCookieBar ? 0 : -1}
                aria-label="Decline Cookies"
                className="buttonHover  flex justify-center items-center h-[100%] relative rounded-full bg-[#ffffff] font-semibold text-[18px] px-8 py-2 text-[#1757A3] cookie_decline cursor-pointer"
                onClick={declineCookie}
              >
                Decline
              </span>
              <span
                tabIndex={showCookieBar ? 0 : -1}
                aria-label="Accept Cookies"
                className="buttonHover  flex justify-center items-center h-[100%] relative rounded-full bg-[#1757A3] font-semibold text-[18px] px-8 py-2 text-white cookie_decline cursor-pointer"
                onClick={acceptCookie}
              >
                Accept
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
