"use client";
import rehypeRaw from 'rehype-raw'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemState,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { TfiClose, TfiAngleRight, TfiAngleLeft } from "react-icons/tfi";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { useState, useEffect } from 'react';

export const AccordionSection = ({ data, id }) => {

  //console.log(data)

  const [openItemId, setOpenItemId] = useState(null);
  const [hash, setHash] = useState(null);

  useEffect(() => {
    // Extract the ID from the URL hash
    const hash = window.location.hash.substring(1);
    if (hash) {
      setHash(hash)
      setOpenItemId(hash);
      // Smooth scroll to the element with the corresponding ID
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        // Clear the anchor tag from the URL after scrolling
        history.replaceState(null, null, ' ');
      }
    }
  }, []);


  function setId(heading) {

    if (hash) {
      if (openItemId === heading) {
        setOpenItemId(null)
      } else {
        setOpenItemId(heading)
      }

    }

  }
  //update redirects2

  const openByDefault = data.accordion.open_by_default;
  return (
    <>
      <section id={id} className="Accordion relative mt-[75px]">

        <div className='w-[85%] mx-auto'>

          <HeaderTwo classes="!text-center !w-[100%] mb-[50px] ">{data.accordion.heading}</HeaderTwo>

          <Accordion allowZeroExpanded className="">
            {data.accordion.content.map((item, index) => (
              <AccordionItem
                uuid={index}

                onClick={() => setId(item.heading.trim().split(' ').slice(0, 2).join(''))}
                // onClick={() =>  hash && openItemId === item.heading.trim().split(' ')[0] ? setOpenItemId(null) : setOpenItemId(item.heading.trim().split(' ')[0])}
                {...(hash ? { dangerouslySetExpanded: openItemId === item.heading.trim().split(' ').slice(0, 2).join('') ? true : undefined } : {})}
                dangerouslySetExpanded={(data.accordion.open_by_default === true || openItemId === item.heading.trim().split(' ').slice(0, 2).join('')) ? true : null}

                id={item.heading.trim().split(' ').slice(0, 2).join('')} key={item.heading} className="py-[10px]">
                <AccordionItemHeading>
                  <AccordionItemButton className="flex items-center justify-start gap-[25px] py-[10px] border-b-[0.5px] border-[#e7e7e7] text-[24px] font-semibold">
                    {item.heading}
                    <AccordionItemState>
                      {({ expanded }) => (expanded ?
                        <TfiAngleLeft className='cursor-pointer' size={18} color='#000000' />
                        :
                        <TfiAngleRight className='cursor-pointer' size={18} color='#000000' />
                      )}
                    </AccordionItemState>
                  </AccordionItemButton>

                </AccordionItemHeading>

                <AccordionItemPanel>
                  <ReactMarkdown className="text-left w-[85%] mt-[25px] text-[16px] leading-relaxed" rehypePlugins={[rehypeRaw]}>
                    {item.body.replace(/\n/gi, "<br/> \n")}
                  </ReactMarkdown>


                </AccordionItemPanel>



              </AccordionItem>
            ))}
          </Accordion>

        </div>
      </section>
    </>
  )
}
