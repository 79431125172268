"use client";
import Image from "next/image";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Slider from "react-slick";
import { useEffect, useRef } from "react";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      aria-label="Previous Slide"
      onClick={onClick}
      className="prev z-[5] translate-y-[-50%] tabindexlink absolute top-[50%] left-[-30px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleLeft size={20} color="#fff" />
    </button>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      aria-label="Next Slide"
      onClick={onClick}
      className="next focus:outline tabindexlink  translate-y-[-50%] absolute top-[50%]  right-[-30px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleRight size={20} color="#fff" />
    </button>
  );
};

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "../Elements/Button/Button";
import { useState } from "react";
export const InfoSlider = ({ data, id }) => {
  const root_ref = useRef(null);
  const [dotsVisible, setDotsVisible] = useState(false);

  useEffect(() => {
    if (data.slide.length > data.columns) {
      setDotsVisible(true);
    }

    if (data.slide.length > 1 && window.innerWidth < 1000) {
      setDotsVisible(true);
    }
  }, []);

  useEffect(() => {
    // Add aria-labels to each dot after the slider has initialized
    const dots = document.querySelectorAll(".slick-dots li button");
    dots.forEach((dot, index) => {
      const slideNumber = index + 1;
      dot.setAttribute("aria-label", `go to slide ${slideNumber}`);
    });
  }, []); // Empty dependency array to run the effect once on mount

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  const buttonRef = useRef(null);

  //   useEffect(() => {
  //     // Find the tallest button height
  //     const tallestButtonHeight = Math.max(
  //       ...data.slide.map((button) => buttonRef.current.offsetHeight)
  //     );

  //     // Set the height of all buttons to the tallest button's height
  //     data.slide.forEach((button) => {
  //       buttonRef.current.style.height = `${tallestButtonHeight}px`;
  //     });
  //   }, [data.slide]);

  useEffect(() => {
    // Select all elements with the ".infoSliderButton" class
    var elements = document.querySelectorAll(".infoSliderButton");

    var parent = document.querySelectorAll(".sliderChild");

    // Initialize a variable to keep track of the maximum height
    var maxHeight = 0;

    // Iterate through the selected elements
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      // Get the height of the current element
      var elementHeight = element.clientHeight; // Use clientHeight to account for padding

      // Update the maxHeight if the current element's height is greater
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    // Set the height of all elements to the maxHeight
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      var maxHeightMinusOffset = maxHeight - 25;

      // Set the height of the current element to maxHeight
      element.style.height = maxHeight + "px";
    }

    parent.forEach((parent) => {
      parent.style.marginBottom = maxHeightMinusOffset + "px";
    });

    //console.log(maxHeight)
  }, []);

  // Format URLs for aria labels
  const formatted = url => {
    // Remove trailing slash if it exists
    const cleanedUrl = url.replace(/\/$/, ''); // Remove trailing slash

    // Split the URL and take the last part
    const part = cleanedUrl.split('/').pop();

    // Strip the file extension
    const partWithoutExtension = part.replace(/\.[^/.]+$/, ''); // Remove file extension

    // Replace hyphens with spaces and capitalize each word
    return partWithoutExtension
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join back into a string
  };

  useEffect(() => {
    // Get all elements with the class "infoSliderHeader"
    const elements = root_ref.current.querySelectorAll(".infoSliderHeader");
    console.log(elements);

    // Initialize variables to keep track of the largest height and its corresponding element
    let largestHeight = 0;
    let largestElement = null;

    // Loop through the elements to find the one with the largest height
    elements.forEach((element) => {
      const elementHeight = element.clientHeight; // Get the element's height

      // Check if the current element's height is greater than the largest height found so far
      if (elementHeight > largestHeight) {
        largestHeight = elementHeight;
        largestElement = element;
      }
    });

    // After finding the largest height, loop through the elements again to set their heights
    elements.forEach((element) => {
      element.style.height = largestHeight + "px";
    });
  }, []);
  return (
    <>
      <section
        id={id}
        ref={root_ref}
        className={
          dotsVisible
            ? "max-w-[100%] InfoSlider w-[100%] h-[auto] py-[75px] bg-transparent flex items-center justify-center flex-col mx-auto relative z-[2]"
            : "max-w-[100%] InfoSlider w-[100%] h-[auto] pt-[75px] bg-transparent flex items-center justify-center flex-col mx-auto relative z-[2]"
        }
      >
        {data.header ? (
          <HeaderTwo classes=" mb-[50px]">{data.header}</HeaderTwo>
        ) : null}

        {data.body ? (
          <ReactMarkdown
            className="w-[90%] lg:w-[50%] mb-[25px] text-center text-[18px] mx-auto leading-relaxed"
            rehypePlugins={[rehypeRaw]}
          >
            {data.body.replace(/\n/gi, "<br/> \n")}
          </ReactMarkdown>
        ) : null}

        <div className="w-[80%] mx-auto gap-[25px] slider-container ">
          {/* IF COLUMNS = 3 */}

          {data.columns == 3 ? (
            <Slider {...settings3}>
              {data.slide.map((sliderData) => {
                //console.log(sliderData)
                return (
                  <div
                    key={sliderData.button_url}
                    className={
                      sliderData.button_url
                        ? "sliderChild flex justify-start flex-col  lg:mb-[85px] items-center w-1/4 px-[25px] text-center"
                        : "sliderChild flex justify-start flex-col  items-center w-1/4 px-[25px] text-center"
                    }
                  >
                    <div
                      className={
                        "sliderInner relative w-[100%] h-[100%] flex justify-start items-stretch"
                      }
                    >
                      <div
                        style={{ backgroundColor: `${sliderData.background}` }}
                        className={`flex flex-col justify-start items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] py-[50px]`}
                      >
                        {sliderData.font_awesome_icon ? (
                          <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                            <i
                              aria-hidden="true"
                              style={{
                                color: `${sliderData.font_awesome_icon_color}`,
                              }}
                              className={` fa ${sliderData.font_awesome_icon}`}
                            ></i>
                          </div>
                        ) : null}

                        {sliderData.slide_icon.url ? (
                          <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                            <img
                              fetchPriority="high"
                              className="w-[100%] h-[100%] object-contain"
                              src={sliderData.slide_icon.url}
                              alt={sliderData.slide_icon.alt}
                              height={65}
                              width={65}
                            />
                          </div>
                        ) : null}

                        {sliderData.slide_image.url ? (
                          <div className="w-[60%] lg:w-[80%] flex justify-center flex-col items-center mb-[15px]">
                            <img
                              fetchPriority="high"
                              className=""
                              src={sliderData.slide_image.url}
                              alt={sliderData.slide_image.alt}
                            />
                          </div>
                        ) : null}

                        {sliderData.slide_heading
                          ? (() => {
                            switch (sliderData.header_type) {
                              case "h1":
                                return (
                                  <h1
                                    style={{
                                      color: `${sliderData.text_color}`,
                                      textAlign: `${sliderData.text_align}!important`,
                                    }}
                                    className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px]"
                                  >
                                    {sliderData.slide_heading}
                                  </h1>
                                );

                              case "h2":
                                return (
                                  <h2
                                    style={{
                                      color: `${sliderData.text_color}`,
                                      textAlign: `${sliderData.text_align}!important`,
                                    }}
                                    className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px]"
                                  >
                                    {sliderData.slide_heading}
                                  </h2>
                                );

                              case "h3":
                                return (
                                  <h3
                                    style={{
                                      color: `${sliderData.text_color}`,
                                      textAlign: `${sliderData.text_align}!important`,
                                    }}
                                    className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px]"
                                  >
                                    {sliderData.slide_heading}
                                  </h3>
                                );

                              case "h4":
                                return (
                                  <h4
                                    style={{
                                      color: `${sliderData.text_color}`,
                                      textAlign: `${sliderData.text_align}!important`,
                                    }}
                                    className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px]"
                                  >
                                    {sliderData.slide_heading}
                                  </h4>
                                );

                              case "h5":
                                return (
                                  <h5
                                    style={{
                                      color: `${sliderData.text_color}`,
                                      textAlign: `${sliderData.text_align}!important`,
                                    }}
                                    className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px]"
                                  >
                                    {sliderData.slide_heading}
                                  </h5>
                                );

                              case "h6":
                                return (
                                  <h6
                                    style={{
                                      color: `${sliderData.text_color}`,
                                      textAlign: `${sliderData.text_align}!important`,
                                    }}
                                    className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px]"
                                  >
                                    {sliderData.slide_heading}
                                  </h6>
                                );

                              default:
                                return null;
                            }
                          })()
                          : null}

                        {sliderData.slide_body ? (
                          <span
                            className="mb-[20px] text-md w-[85%] leading-[2]"
                            style={{
                              color: `${sliderData.text_color}!important`,
                              textAlign: `${sliderData.text_align}!important`,
                            }}
                          >
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                              {sliderData.slide_body.replace(
                                /\n/gi,
                                "<li className='list-none mt-[10px]'></li>",
                              )}
                            </ReactMarkdown>
                          </span>
                        ) : null}

                        {sliderData.button ? (
                          <Button
                            aria={formatted(sliderData.button_url)}

                            innerClasses=" xl:w-auto w-[100%]"
                            classes={
                              "w-[95%] xl:w-auto infoSliderButton !absolute !px-[5px]  !bottom-[25px] "
                            }
                            url={sliderData.button_url}
                          >
                            {sliderData.button_text}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : null}

          {/* IF COLUMNS = 2 */}

          {data.columns == 2 ? (
            <Slider {...settings2}>
              {data.slide.map((sliderData) => {
                //console.log(sliderData)
                return (
                  <div
                    key={sliderData.button_url}
                    className={
                      sliderData.button_url
                        ? "sliderChild flex justify-start flex-col  lg:mb-[85px] items-center w-1/4 px-[25px] text-center"
                        : "sliderChild flex justify-start flex-col  items-center w-1/4 px-[25px] text-center"
                    }
                  >
                    <div
                      className={
                        "sliderInner relative w-[100%] h-[100%] flex justify-start items-stretch"
                      }
                    >
                      <div
                        style={{ backgroundColor: `${sliderData.background}` }}
                        className={`flex flex-col justify-start items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] py-[50px]`}
                      >
                        {sliderData.font_awesome_icon ? (
                          <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                            <i
                              aria-hidden="true"
                              style={{
                                color: `${sliderData.font_awesome_icon_color}`,
                              }}
                              className={` fa ${sliderData.font_awesome_icon}`}
                            ></i>
                          </div>
                        ) : null}

                        {sliderData.slide_icon.url ? (
                          <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                            <img
                              fetchPriority="high"
                              className="w-[100%] h-[100%] object-contain"
                              src={sliderData.slide_icon.url}
                              alt={sliderData.slide_icon.alt}
                              height={65}
                              width={65}
                            />
                          </div>
                        ) : null}

                        {sliderData.slide_image.url ? (
                          <div className="w-[60%] lg:w-[80%] flex justify-center flex-col items-center mb-[15px]">
                            <img
                              fetchPriority="high"
                              className=""
                              src={sliderData.slide_image.url}
                              alt={sliderData.slide_image.alt}
                            />
                          </div>
                        ) : null}

                        {sliderData.slide_heading ? (
                          <h4
                            style={{
                              color: `${sliderData.text_color}`,
                              textAlign: `${sliderData.text_align}!important`,
                            }}
                            className="infoSliderHeader mb-[20px] w-[85%] font-semibold text-center pt-[10px] "
                          >
                            {sliderData.slide_heading}
                          </h4>
                        ) : null}

                        {sliderData.slide_body ? (
                          <span
                            className="mb-[20px] text-md w-[85%] leading-[2]"
                            style={{
                              color: `${sliderData.text_color}!important`,
                              textAlign: `${sliderData.text_align}!important`,
                            }}
                          >
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                              {sliderData.slide_body.replace(
                                /\n/gi,
                                "<li className='list-none mt-[10px]'></li>",
                              )}
                            </ReactMarkdown>
                          </span>
                        ) : null}

                        {sliderData.button ? (
                          <Button
                            aria={formatted(sliderData.button_url)}

                            innerClasses=" xl:w-auto w-[100%]"
                            classes={
                              "w-[95%] xl:w-auto infoSliderButton !absolute !px-[5px] bottom-[25px] "
                            }
                            url={sliderData.button_url}
                          >
                            {sliderData.button_text}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : null}

          {/* IF COLUMNS = 1*/}

          {data.columns == 1 ? (
            <Slider {...settings1}>
              {data.slide.map((sliderData) => {
                //console.log(sliderData)
                return (
                  <div
                    key={sliderData.button_url}
                    className={
                      sliderData.button_url
                        ? "sliderChild flex justify-start flex-col  lg:mb-[85px] items-center w-1/4 px-[25px] text-center"
                        : "sliderChild flex justify-start flex-col  items-center w-1/4 px-[25px] text-center"
                    }
                  >
                    <div
                      className={
                        "sliderInner relative w-[100%] h-[100%] flex justify-start items-stretch"
                      }
                    >
                      <div
                        style={{ backgroundColor: `${sliderData.background}` }}
                        className={`flex flex-col justify-start items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] py-[50px]`}
                      >
                        {sliderData.font_awesome_icon ? (
                          <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                            <i
                              aria-hidden="true"
                              style={{
                                color: `${sliderData.font_awesome_icon_color}`,
                              }}
                              className={` fa ${sliderData.font_awesome_icon}`}
                            ></i>
                          </div>
                        ) : null}

                        {sliderData.slide_icon.url ? (
                          <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                            <img
                              fetchPriority="high"
                              className="w-[100%] h-[100%] object-contain"
                              src={sliderData.slide_icon.url}
                              alt={sliderData.slide_icon.alt}
                              height={65}
                              width={65}
                            />
                          </div>
                        ) : null}

                        {sliderData.slide_image.url ? (
                          <div className="w-[60%] lg:w-[80%] flex justify-center flex-col items-center mb-[15px]">
                            <img
                              fetchPriority="high"
                              className=""
                              src={sliderData.slide_image.url}
                              alt={sliderData.slide_image.alt}
                            />
                          </div>
                        ) : null}

                        {sliderData.slide_heading ? (
                          <h4
                            style={{
                              color: `${sliderData.text_color}`,
                              textAlign: `${sliderData.text_align}!important`,
                            }}
                            className=" infoSliderHeader w-[85%] mb-[20px] font-semibold text-center pt-[10px] "
                          >
                            {sliderData.slide_heading}
                          </h4>
                        ) : null}

                        {sliderData.slide_body ? (
                          <span
                            className="mb-[20px] text-md w-[85%] leading-[2]"
                            style={{
                              color: `${sliderData.text_color}!important`,
                              textAlign: `${sliderData.text_align}!important`,
                            }}
                          >
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                              {sliderData.slide_body.replace(
                                /\n/gi,
                                "<li className='list-none mt-[10px]'></li>",
                              )}
                            </ReactMarkdown>
                          </span>
                        ) : null}

                        {sliderData.button ? (
                          <Button
                            aria={formatted(sliderData.button_url)}
                            innerClasses="xl:w-auto w-[100%]"
                            classes={
                              "w-[95%] xl:w-auto infoSliderButton !absolute !px-[5px] !bottom-[25px] "
                            }
                            url={sliderData.button_url}
                          >
                            {sliderData.button_text}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : null}
        </div>
      </section>
    </>
  );
};

