"use client";
import Image from "next/image";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import { TfiAngleUp } from "react-icons/tfi";
import { TfiAngleDown } from "react-icons/tfi";

import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Slider from "react-slick";
import { useEffect, useState } from "react";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="prev tabindexlink absolute top-[50%] left-[-40px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleLeft size={20} color="#fff" />
    </button>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="next focus:outline tabindexlink absolute top-[50%] right-[-40px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleRight size={20} color="#fff" />
    </button>
  );
};

import { Button } from "../Elements/Button/Button";

export const WrappedContentCollapsible = ({ data, id }) => {
  //console.log("###")
  //console.log(data)
  //console.log("###")

  const [collapse, setCollapse] = useState(true);

  const handleClick = () => {
    setCollapse(!collapse); // Toggle the value of 'collapse'
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Call your function when Enter key is pressed
      handleClick();
    }
  };

  return (
    <>
      <section
        id={id}
        className="max-w-[100%] WrappedContentCollapsible w-[100%] h-[auto] py-[25px] bg-transparent flex items-center justify-center flex-col mx-auto relative z-[2] "
      >
        {data.header ? (
          <div
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            className="tabindexlink cursor-pointer flex flex-row px-[10px] py-[20px] lg:justify-start  justify-center lg:pl-[10%] items-center gap-[25px] w-[100%] bg-[#fafafa] "
          >
            <HeaderTwo
              onKeyDown={handleKeyDown}
              onClick={handleClick}
              classes="!w-auto !text-[30px]"
            >
              {data.header}
            </HeaderTwo>
            {collapse == false ? (
              <TfiAngleUp size={30} color={"#000"} />
            ) : (
              <TfiAngleDown size={30} color={"#000"} />
            )}
          </div>
        ) : null}

        <div
          className={
            collapse == true
              ? "w-[80%] mx-auto gap-[25px] !my-[50px] slider-container flex-row hidden flex-wrap "
              : "w-[80%] mx-auto gap-[25px] !my-[50px] slider-container flex-row flex flex-wrap "
          }
        >
          {data.content
            ? data.content.map((wrappedData) => {
              return (
                <div
                  key={wrappedData.font_awesome_icon + wrappedData.icon.url + wrappedData.header}
                  className={
                    data.columns == 4
                      ? "sliderChild flex justify-start flex-col items-center w-[100%] md:w-[45%] xl:w-[23%] !h-auto my-[20px] px-[25px] text-center "
                      : "sliderChild flex justify-start flex-col items-center w-[100%] md:w-[45%] xl:w-[32%] !h-auto my-[20px] px-[25px] text-center "
                  }
                >
                  <div className="w-[100%] h-[100%] flex justify-start items-stretch ">
                    <div className="flex flex-col justify-start items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] py-[50px]  bg-[#FAFAFA]">
                      {wrappedData.font_awesome_icon ? (
                        <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                          <i
                            aria-hidden="true"
                            style={{
                              color: `${wrappedData.font_awesome_icon_color}`,
                            }}
                            className={` fa ${wrappedData.font_awesome_icon}`}
                          ></i>
                        </div>
                      ) : null}

                      {wrappedData.icon.url ? (
                        <div className="h-[50px] w-[50px] flex justify-center flex-col items-center ">
                          <img
                            fetchPriority="high"
                            className="w-[65px]"
                            src={wrappedData.icon.url}
                            alt={wrappedData.icon.alt}
                            height={65}
                            width={65}
                          />
                        </div>
                      ) : null}

                      {wrappedData.image.url ? (
                        <div className="w-[70%] flex justify-center flex-col items-center ">
                          <img
                            fetchPriority="high"
                            src={wrappedData.image.url}
                            alt={wrappedData?.image?.alt}
                          />
                        </div>
                      ) : null}

                      {wrappedData.header ? (
                        <h5
                          tabIndex="0"
                          className="tabindexlink text-[20px] mt-[20px] font-semibold text-center pt-[10px] "
                        >
                          {wrappedData.header}
                        </h5>
                      ) : null}

                      {wrappedData.body ? (
                        <ReactMarkdown
                          tabIndex="0"
                          className="tabindexlink my-[20px] text-md leading-[2]"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {wrappedData.body.replace(
                            /\n/gi,
                            "<li className='list-none mt-[10px]'></li>"
                          )}
                        </ReactMarkdown>
                      ) : null}

                      {wrappedData.button ? (
                        <Button
                          classes={"!mt-[15px]"}
                          url={wrappedData.button_url}
                        >
                          {wrappedData.button_text}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
            : null}
        </div>
      </section>
    </>
  );
};
