"use client";
import Image from "next/image"
import { useEffect, useState } from "react";
import { TfiAngleDown } from "react-icons/tfi";
import Slider from "react-slick";
import { v4 as uuidv4 } from 'uuid';

import rehypeRaw from 'rehype-raw'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { setConfig } from "next/config";


export const Hero = ({ data, id }) => {

  //console.log(data)

  const settings = {
    useTransform: false,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,

    arrows: true,

  };
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  //console.log(data)

  const [isLoading, setLoading] = useState(true);

  const [isReady, setIsReady] = useState(false);
  // live branch
  return (
    <>
      <section id={id} className="Hero max-w-[100%] h-[calc(100vh-125px)] w-[100%] relative overflow-hidden">

        <div className="heroCircle bg-white w-[250%] z-[2] lg:w-[165%] absolute top-[-55%] lg:top-[-30%] rounded-[50%] left-[50%] translate-x-[-50%]  h-[100%] lg:h-[70%]"></div>


        <div className="heroHeaderDiv h-[30%] z-[2] bg-[#fff] w-[100%] relative flex justify-center items-center flex-col gap-5">

          <h1 className="heroHeadingMain !text-[28px] leading-[30px] lg:!text-[2.2rem] 2xl:!text-[2.6rem] font-semibold  w-[90%] lg:w-[70%] lg:leading-[45px] xl:leading-[65px] text-center ">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {data.hero.hero_heading.replace(/\n/gi, "<li className='list-none mt-[10px]'></li>")}
            </ReactMarkdown>
          </h1>

          {data.hero.hero_subheading.length > 0 ?
            <h2 className="hero_subheading max-[400px]:text-xl text-xl leading-[40px] font-normal lg:text-3xl z-[2] w-[90%] lg:w-[70%] lg:leading-[65px] text-center ">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {data.hero.hero_subheading.replace(/\n/gi, "<br/> \n")}
              </ReactMarkdown>

            </h2>
            : null}
        </div>

        {/* SINGLE BACKGROUND IMAGE  */}
        {data.hero.hero_image ?



          <div className="h-[70%] z-[1] w-[100%] relative">



            {data.hero.hero_mobile_image.url ?

              <>
                {/* DESKTOP IMAGE */}
                <img className="md:flex hidden transition duration-1000 w-[100%] h-[100%] !object-cover" style={{ objectPosition: `${data.hero.hero_image_focal_point}` }} alt={data.hero.hero_image.alt ? data.hero.hero_image.alt : ""} src={data.hero.hero_image.url} fetchPriority="high" priority={"true"} />

                {/* MOBILE  IMAGE */}
                <img className="flex md:hidden transition duration-1000 w-[100%] h-[100%] !object-cover" alt={data.hero.hero_mobile_image.alt ? data.hero.hero_mobile_image.alt : ""} src={data.hero.hero_mobile_image.url} fetchPriority="high" priority={"true"} />
              </>
              :
              <>
                {/* DESKTOP IMAGE */}
                <img className="transition duration-1000 w-[100%] h-[100%] !object-cover" style={{ objectPosition: `${data.hero.hero_image_focal_point}` }} alt={data.hero.hero_image.alt ? data.hero.hero_image.alt : ""} src={data.hero.hero_image.url} fetchPriority="high" priority={"true"} />
              </>
            }

          </div>

          : null}



        {/*BACKGROUND IMAGE SLIDER */}

        {data.hero.background_images ?
          <Slider className="heroBGimages relative h-[70%]"{...settings}>

            {data.hero.background_images.map((background) => {
              return (
                <div key={background.image.url ?? "ukn"} tabIndex="-1" className="slide h-[100%] z-[1] w-[100%] relative">
                  {/* DESKTOP IMAGE */}
                  <img tabIndex="-1" className="transition duration-1000 w-[100%] h-[100%] !object-cover" style={{ objectPosition: `${background.focal_point}` }} alt={background.image.alt ? background.image.alt : ""} src={background.image.url ? background.image.url : '/image.png'} fetchPriority="high" priority={"true"} />
                  {/* OVERLAY */}

                  {/* <div className="absolute top-0 left-0 w-[100%] h-[100%] bg-black/40"></div> */}
                </div>
              )
            })}


          </Slider>
          : null}

        {/* SCROLL TO EXPLORE */}


        {data.hero.scroll_to_explore == true ?
          <div style={{ background: `${data.hero.scroll_to_explore_colour}` }} className="scrollToExplore hidden md:flex h-[120px] w-[120px] lg:h-[150px] text-[16px] z-[2] lg:w-[150px] absolute top-[35%] lg:top-[30%] flex-col rounded-full left-[50%] translate-x-[-50%] z-[1] flex justify-center items-center">
            <p style={{ color: `${data.hero.scroll_to_explore_text_colour}` }} className="text-[14px] w-[50%] text-center mb-[10px]">Scroll to explore</p>
            <TfiAngleDown size={20} color={data.hero.scroll_to_explore_text_colour} />
          </div>

          : null}

      </section>
    </>
  )
}
