"use client";
import Image from "next/image";
import { HeaderTwo } from "../Elements/HeaderTwo/HeaderTwo";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import { v4 as uuidv4 } from "uuid";
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Slider from "react-slick";
import { use, useEffect } from "react";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="prev tabindexlink absolute top-[50%] left-[-40px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleLeft size={20} color="#fff" />
    </button>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="next focus:outline tabindexlink absolute top-[50%] right-[-40px] bg-[#1757A3] rounded-full p-[10px]"
    >
      <TfiAngleRight size={20} color="#fff" />
    </button>
  );
};

import { Button } from "../Elements/Button/Button";

export const WrappedContent = ({ data, id }) => {
  useEffect(() => {
    // Select all elements with the ".infoSliderButton" class
    var elements = document.querySelectorAll(".wrappedButton");

    var parent = document.querySelectorAll(".wrappedParent ");
    var parentInner = document.querySelectorAll(".sliderInner ");

    // Initialize a variable to keep track of the maximum height
    var maxHeight = 0;

    // Iterate through the selected elements
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      // Get the height of the current element
      var elementHeight = element.clientHeight; // Use clientHeight to account for padding

      // Update the maxHeight if the current element's height is greater
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    // Set the height of all elements to the maxHeight
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      var maxHeightMinusOffset = maxHeight + 25;

      // Set the height of the current element to maxHeight
      element.style.height = maxHeight + "px";
    }

    parent.forEach((parent) => {
      parent.style.paddingBottom = maxHeightMinusOffset + "px";
    });

    //console.log(maxHeight)
  }, []);

  //console.log("###")
  //console.log(data)
  //console.log("###")

  return (
    <>
      <section
        id={id}
        className="max-w-[100%] WrappedContent w-[100%] h-[auto] pt-[75px] bg-transparent flex items-center justify-center flex-col mx-auto relative z-[2] "
      >
        {data.header ? <HeaderTwo>{data.header}</HeaderTwo> : null}

        {data.body ? (
          <ReactMarkdown
            className="w-[90%] lg:w-[50%] mt-[50px] text-center text-[18px] mx-auto leading-relaxed"
            rehypePlugins={[rehypeRaw]}
          >
            {data.body.replace(/\n/gi, "<br/> \n")}
          </ReactMarkdown>
        ) : null}

        <div className="w-[90%] sm:w-[90%] mx-auto gap-[1%] !mt-[50px] slider-container justify-center flex-row flex flex-wrap ">
          {data.content
            ? data.content.map((wrappedData) => {
              return (
                <div
                  key={wrappedData.image.url}
                  className={
                    data.columns == 4
                      ? "sliderChild flex justify-start flex-col items-center w-[100%] md:w-[45%] xl:w-[24%] !h-auto my-[20px] px-[25px] text-center relative "
                      : "sliderChild relative flex justify-start flex-col items-center w-[100%] md:w-[45%] xl:w-[32%] !h-auto my-[20px] px-[25px] text-center "
                  }
                >
                  <div className="sliderInner w-[100%] h-[100%] flex justify-start items-stretch ">
                    <div
                      style={{
                        backgroundColor: `${wrappedData.transparent_background
                          ? "transparent"
                          : "#FAFAFA"
                          }`,
                      }}
                      className={
                        wrappedData.transparent_background
                          ? "flex flex-col justify-start items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] wrappedParent"
                          : "wrappedParent flex flex-col justify-start items-center w-[100%] rounded-[50px] px-[10px] sm:px-[25px] py-[50px]"
                      }
                    >
                      {wrappedData.font_awesome_icon ? (
                        <div className="!h-[50px] !w-[50px] overflow-hidden flex justify-center flex-col mb-[15px] items-center ">
                          <i
                            aria-hidden="true"
                            style={{
                              color: `${wrappedData.font_awesome_icon_color}`,
                            }}
                            className={` fa ${wrappedData.font_awesome_icon}`}
                          ></i>
                        </div>
                      ) : null}

                      {wrappedData.icon.url ? (
                        <div className="h-[50px] w-[50px] flex justify-center flex-col items-center ">
                          <img
                            fetchPriority="high"
                            className="w-[65px]"
                            src={wrappedData.icon.url}
                            alt={wrappedData.icon.alt}
                            height={65}
                            width={65}
                          />
                        </div>
                      ) : null}

                      {wrappedData.image.url ? (
                        <div
                          className={
                            "w-[70%] flex justify-center flex-col items-center"
                          }
                        >
                          <img
                            className={
                              wrappedData.rounded_image == true
                                ? "rounded-full aspect-square object-cover"
                                : null
                            }
                            fetchPriority="high"
                            src={wrappedData.image.url}
                            alt={wrappedData?.image?.alt}
                          />
                        </div>
                      ) : null}

                      {wrappedData.header ? (
                        <h4 className="text-[20px] min-h-[69px] mt-[20px] font-semibold text-center pt-[10px] ">
                          {wrappedData.header}
                        </h4>
                      ) : null}

                      {wrappedData.body ? (
                        <ReactMarkdown
                          className={
                            wrappedData.button
                              ? "my-[20px] mb-[60px] text-md leading-[2]"
                              : "my-[20px] text-md leading-[2]"
                          }
                          rehypePlugins={[rehypeRaw]}
                        >
                          {wrappedData.body.replace(
                            /\n/gi,
                            "<li className='list-none mt-[10px]'></li>"
                          )}
                        </ReactMarkdown>
                      ) : null}

                      {wrappedData.button ? (
                        <Button
                          classes={
                            "wrappedButton !mt-[15px] !absolute bottom-[25px] "
                          }
                          url={wrappedData.button_url}
                        >
                          {wrappedData.button_text}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
            : null}
        </div>
      </section>
    </>
  );
};
